import React from 'react';

// Material UI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import { Collapse } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import TurnedInSharpIcon from '@mui/icons-material/TurnedInSharp';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

export default function PaginationSave(props) {

    const {
        saveSearch,
        setSaveName,
        saved,
        openedSave,
        setOpenedSave,
        setExpanded,
        contentMenuHide
    } = props;

    const toggleIsOpen = () => {
        if (openedSave === false) {
            setExpanded(false);
        }
        setOpenedSave(current => !current);
    };

    function saveSearchPre() {
        setOpenedSave(false);
        setTimeout(()=>{
            saveSearch();
        },200);
    }

    // Close save menu if click outside
    function handleClickAwaySave () {
        if (openedSave === true) {
            setOpenedSave(false);
        }
        // document.getElementById('input-with-sx').value = '';
        setSaveName();
    }

    return (

        <> 
            <Fade className="content-menu__save-box content-menu-collapsed-save" in={openedSave} unmountOnExit={true}>
                
                {/* <Box className='content-menu__save-box'> */}
                {/* <div><ClickAwayListener onClickAway={()=>handleClickAwaySave()}> */}

                    <TextField
                    className='content-menu__input-save'
                    onChange={(event) => {
                        setSaveName(event.target.value);
                    }} 
                    id="input-with-sx" 
                    label="Name of save" 
                    variant="outlined"
                    color="primary_light"
                    InputProps={{
                        endAdornment: (
                        <InputAdornment sx={{ width: '40%'}} position="end">
                            <Button
                            className='content-menu__input-button'
                            size={'small'} 
                            variant='contained' 
                            color="primary_grey_light" 
                            onClick={!saved ? saveSearchPre : null}
                            endIcon={<LabelImportantIcon />}
                            sx={{ width: '100%'}}
                            >
                                Save
                            </Button>
                            
                            <IconButton 
                            className="content-menu__close" 
                            size="small" 
                            aria-label="close"
                            onClick={handleClickAwaySave}
                            >
                                <CloseIcon fontSize="small"/>
                            </IconButton>

                        </InputAdornment>
                        ),
                    }}
                    
                    />
                {/* </ClickAwayListener></div> */}
                {/* </Box> */}
            </Fade>

        <Button 
            disabled={contentMenuHide}
            size={'small'}
            className='content-menu__button'
            sx={{ marginLeft: 'auto'}}
            variant="contained" 
            startIcon={!saved ? <TurnedInSharpIcon  /> : <DoneIcon />}
            // onClick={!saved ? saveSearch : null}
            onClick={!saved ? toggleIsOpen : null}
            type="button" aria-label="Name the save"
            color="primary_grey_light"
        >
            {!saved ? 'Save search' : 'Saved'}

        </Button>
        
        </>

        
    );
                  
    
}