import React from 'react';

export default function DBUserItem(props) {

    const {item,key} = props;

    return (
        <div className='dashboard__item' key={key}>

            <div className='dashboard__ids column-value'>
                {item.customer_id}
            </div>

            <div className='dashboard__emails column-value'>
                <p>{item.billing_email}</p>
                <p>{item.vinaty_user_data?.ip}</p>
            </div>

            <div className='dashboard__status column-value'>

                {item.all_orders?.map((order,id)=>{
                    return(
                        <span className="status__label" key={id+'-product'}>
                        {(order.products[0] !== 'Three-month access' && order.products[0] !== 'Six-month access' && order.products[0] !== 'Twelve-month access') ?
                        'Bought a country' 
                        : order.products[0]}
                        </span>
                    )
                })}

                <span className='status__dates'>

                    <span>
                    Rg -- {item.user_registered?.toString().slice(0,10)}
                    </span>
                    <span>
                    St -- {item.date_created_gmt?.slice(0, 10)}
                    </span>
                    <span>
                    En -- Unknown
                    </span>

                </span>
            </div>

            <div className='dashboard__date column-value'>
                <span>
                {item.vinaty_user_data?.last_visit 
                ? new Date(item.vinaty_user_data.last_visit * 1000)
                    .toISOString()
                    .replace('T', ' ')
                    .slice(0, 19) 
                : 'Invalid date'}
                </span>
            </div>

            <div className='dashboard__calc column-value'>
                <div>
                    <span>
                        Начислено:
                    </span>
                    <span>
                        {' ' + item.vinaty_user_data?.credited_total}
                    </span>
                </div>
                <div>
                    <span>
                        Посл. начисление:
                    </span>
                    <span style={{ display: 'block' }}>
                        {' ' + item.vinaty_user_data?.credited_time}
                    </span>
                </div>
                <div>
                    <span>
                        Должны:
                    </span>
                    <span>
                        Unknown
                    </span>
                </div>
            </div>

            <div className='dashboard__downloads column-value'>
                <div>
                    <span>
                        {item.vinaty_user_data?.credits + ' / ' + '300'}
                    </span>
                    <p>
                    <span className='downloads__h-total-used'>
                        Скачано:
                    </span>
                    <span className='downloads__total-used'>
                        {' '}{item.vinaty_user_data?.download_ids?.split(',').length-1 == '0' ? item.vinaty_user_data?.download_ids?.split(',').length-1 : item.vinaty_user_data?.download_ids?.split(',').length-1 + 1}
                    </span>
                    </p>
                </div>
            </div>

            <div className='dashboard__open-links column-value'>
                <div>
                    <span>
                    {item.vinaty_user_data?.link_open}
                    {' / '}
                    {'700'}
                    </span>

                    <p>
                        <span className='open-links__h-total-used'>
                            Просмотры:
                        </span>
                        <span className='open-links__total-used'>
                            {(item.vinaty_user_data?.uniq_link_open.split(',').length)-1}
                        </span>
                    </p>

                </div>
            </div> 

            <div className='dashboard__open-items column-value'>
                {item.vinaty_user_data?.company_open}
            </div> 

            <div className='dashboard__searches column-value'>
                {item.vinaty_user_data?.searches}
            </div> 
            
        </div>
    );
}