import React from 'react';
import Button from '@mui/material/Button';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DescriptionIcon from '@mui/icons-material/Description';
import UserInfo from './UserInfo';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';

// import Switch from '@mui/material/Switch';
// import Feedback from './Feedback';

export default function Heading(props) {

    const {
        // totalDistributors,
        // feedbackUserStatus,
        // setFeedback,
        // setFeedbackForm,
        userPlan,
        setUserFolders,
        setSavedSearches,
        userCounter,
        userDetails,
        setUserDetails,
        getCookie,
        deleteCookies,
        toggleSidebar,
        discountState,
        setDiscountState                  
        // feedback,
        // setFeedback,
        // feedbackForm,
        // setFeedbackForm
        // showChat,
        // setShowChat
    } = props;

    return (

        <div className='heading'>

            <UserInfo 
            setUserFolders={setUserFolders} 
            setSavedSearches={setSavedSearches} 
            userCounter={userCounter} 
            userDetails={userDetails} 
            setUserDetails={setUserDetails} 
            // tempCookie={tempCookie}
            getCookie={getCookie}
            deleteCookies={deleteCookies}
            />

            <div className='heading__buttons'>

                {userDetails['user_plan'] === 4 || userDetails['user_plan'] === 5 || userDetails['user_plan'] === 1 ?
                <Button 
                variant="contained" 
                size="small" 
                startIcon={<ArrowCircleUpSharpIcon />} 
                target="_blank" 
                href="https://vinaty.com/my-account/"
                // className='button--primary'
                color={'green_menu'}
                >
                    <span className='button-text'>Update</span>
                </Button>
                :                 
                <Button 
                variant="outlined" 
                size="small" 
                startIcon={<AccountBoxIcon />} 
                target="_blank" 
                href="https://vinaty.com/my-account/"
                className='button--primary'
                >
                    <span className='button-text'>Account</span>
                </Button>
                }

                {userDetails['user_plan'] === 4 || userDetails['user_plan'] === 5 || userDetails['user_plan'] === 1 ?
                <Button 
                variant="outlined" 
                size="small" 
                startIcon={<LocalOfferSharpIcon />} 
                target="_blank" 
                href="https://vinaty.com/pricing/"
                className='button--primary'
                >
                    <span className='button-text'>Pricing</span>
                </Button>
                : null
                }

                <Button 
                variant="outlined" 
                size="small" 
                startIcon={<DescriptionIcon />} 
                target="_blank" 
                href="https://vinaty.com/docs/"
                className='button--primary'
                >
                    <span className='button-text'>Docs</span>
                </Button>

                <Button 
                variant="outlined" 
                size="small" 
                startIcon={<ContactSupportIcon />} 
                target="_blank" 
                href="https://vinaty.com/support/"
                className='button--primary'
                >
                    <span className='button-text'>Support</span>
                </Button>

                <Button 
                variant="outlined" 
                size="small" 
                startIcon={<ExitToAppIcon />} 
                href="https://vinaty.com/logout/"
                className='button--primary'
                >
                    <span className='button-text'>Log out</span>
                </Button>


                {(userDetails['user_plan'] === 4 || userDetails['user_plan'] === 5 || userDetails['user_plan'] === 1) && discountState.length > 0 ?
            
                <div className='discount'>                     
                    <span className='discount__text'>
                    {discountState[0]['text']}
                    </span>
                </div>
                :
                null
                }

            </div>

            <div id='toggle-sidebar' className="toggle-sidebar toggle-sidebar--active" onClick={toggleSidebar}>
                <MenuIcon /><span>Sidebar</span>
            </div>

        </div>
    );
}