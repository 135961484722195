import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import CountryCodes from '../assets/country_codes.json';

import PhoneData from './PhoneData';
import FoldersAction from './Pagination/FoldersAction';

import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';

import LockSharpIcon from '@mui/icons-material/LockSharp';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import MarkEmailReadSharpIcon from '@mui/icons-material/MarkEmailReadSharp';
import UnsubscribeSharpIcon from '@mui/icons-material/UnsubscribeSharp';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import HelpIcon from '@mui/icons-material/Help';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FaSquareXTwitter } from "react-icons/fa6";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import LaunchIcon from '@mui/icons-material/Launch';
import { IoIosLogIn } from "react-icons/io";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import SpaIcon from '@mui/icons-material/Spa';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WineBarIcon from '@mui/icons-material/WineBar';
import Tooltip from '@mui/material/Tooltip';
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ShareIcon from '@mui/icons-material/Share';

import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam,BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";
import { ImLocation } from "react-icons/im";
import { MdOutlineSell } from "react-icons/md";
import { AiOutlineFlag } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaFolder } from "react-icons/fa";
import { IoIosWine } from "react-icons/io";
import { LiaGlassWhiskeySolid } from "react-icons/lia";
import { RiBeerLine } from "react-icons/ri";
import { PiBeerBottleFill } from "react-icons/pi";
import { FaBottleDroplet } from "react-icons/fa6";

import ReactCountryFlag from "react-country-flag";

const label = { inputProps: { 'aria-label': 'Select distributor' } };

export default function DistributorNew(props) {
    const {
        item,
        id,
        chosenItems,
        setChosenItems,
        setUserDownloadIds,
        downloaded,
        setUserViewsIds,
        viewed,
        userCounter,
        setUserCounter,
        userFolders,
        setUserFolders,
        getCookie,
        emailOpen,
        setEmailOpen,
        // openTypeInfo,
        // setOpenTypeInfo,
        expanded,
        expandActionItem,
        openPersonalEmailInfo,
        setOpenPersonalEmailInfo,
        userPlan,
        openEmailStatusInfo,
        currentFolder,
        pageNumber,
        setLimitViews,
        // downloadExcel,
        SingleDownload,
        changeBookmarkCounter
    } = props;

    const [arrayFoldersItem,setArrayFoldersItem] = useState([]);
    
    // Init var to filter first load 
    const [initLoad,setInitLoad] = useState(2);

    // Note state
    const [noteValue, setNoteValue] = useState('');
    const [postNoteValue] = useDebounce(noteValue, 4000);

    // Bookmark value
    const [inBookmark,setInBookmark] = useState(false);

    // Show email state
    const [itemEmail,setItemEmail] = useState([]);

    // State that phones are loaded anc could be shown
    // const [phonesLoadedState,setPhonesLoadedState] = useState(false);

    // State for stutuses of emails: 1 = all verified, 0 = no emails, 2 = multiple, 3 = invalid
    const [emailStatusState,setEmailStatusState] = useState(-1);
    const [openEmailInfo,setOpenEmailInfo] = useState(0);

    const [emailDateState,setEmailDateState] = useState(-1);

    // If User reporter innacurany
    const [reported,setReported] = useState(false);

    //State to show Importer on the first place 
    const [companyTypesArr,setCompanyTypesArr] = useState([]);

    //State to show Wine on the first place of products
    const [companyProductsArr,setCompanyProductsArr] = useState([]);

    // Open List of Types in Top Part of Company
    const [openTypes,setOpenTypes] = useState(false);

    // Open List of Products in Top Part of Company
    const [openProducts,setOpenProducts] = useState(false);

    // Wait for Types
    const [typesLoaded,setTypesLoaded] = useState(false);

    // Wait for Types
    const [productsLoaded,setProductsLoaded] = useState(false);

    // Write note from content result distributors -> notes
    // Check if item is in bookmarks
    useEffect(()=>{
        setNoteValue(item.notes.replaceAll('&quot;','').replaceAll('&amp;','&').replaceAll('&#039;',"'"));
        if (item.bookmark == '1') {
            setInBookmark(true);
        }

        // Check email statuses
        if (item.verified.length > 0) {
            var validCounter = 0;
            var invalidCounter = 0;
            for(let v = 0;v < item.verified.length;v++) {
                if (
                item.verified[v] === 'valid' || 
                (item.verified[v] === 'do_not_mail' && 
                item.status_details[v] !== 'global_suppression' && 
                item.status_details[v] !== 'toxic' &&
                item.status_details[v] !== 'possible_trap') 
                ) {
                    validCounter = validCounter + 1;
                } 
                if (item.verified[v] === 'invalid') {
                    invalidCounter = invalidCounter + 1;
                }
                if (validCounter === item.verified.length) {
                    setEmailStatusState(1);
                } else if (invalidCounter === item.verified.length) {
                    setEmailStatusState(3);
                } else {
                    setEmailStatusState(2);
                }
            }
        } else {
            setEmailStatusState(0);
        }

        // Chech last update of email
        // if (item?.status_date?.length > 0) {
            var item_email_date = 'not available'; 
            // var list_dates_emails = [];
            var last_email_update;
            if (item?.status_date?.length > 0) {
                item_email_date = item?.status_date?.sort();
                last_email_update = item_email_date[item_email_date.length - 1]
                if (last_email_update !== '0') {
                    last_email_update = convertDate(last_email_update);
                } else {
                    last_email_update = '---';
                }
            } else {
                last_email_update = '---';
            }
            setEmailDateState(last_email_update);
        // } 
        // else {
        //     setEmailDateState();
        // }

        // Sort Types to place Importer to the first place        
        var tempTypesArr = item?.type?.split(',');   
        var tempTypeArrOrder = [];
        for (let i = 0; i < tempTypesArr.length; i++) {
            if (tempTypesArr[i] === 'i' || tempTypesArr[i] === 'importer' || tempTypesArr[i] === 'Importer') {
                tempTypeArrOrder[0] = 'Importer';
            } else if (tempTypesArr[i] === 'd' || tempTypesArr[i] === 'distributor' || tempTypesArr[i] === 'Distributor') {
                tempTypeArrOrder[1] = 'Distributor';
            } else if (tempTypesArr[i] === 'w' || tempTypesArr[i] === 'wholesaler' || tempTypesArr[i] === 'Wholesaler') {
                tempTypeArrOrder[2] = 'Wholesaler';
            } else if (tempTypesArr[i] === 's' || tempTypesArr[i] === 'supermarket' || tempTypesArr[i] === 'Supermarket') {
                tempTypeArrOrder[3] = 'Supermarket';
            } else if (tempTypesArr[i] === 'r' || tempTypesArr[i] === 'retailer' || tempTypesArr[i] === 'Retailer') {
                tempTypeArrOrder[4] = 'Retailer';
            }
        }
        tempTypeArrOrder = tempTypeArrOrder.filter(elm => elm);

        setCompanyTypesArr(tempTypeArrOrder);
        setTimeout(()=>{
            setTypesLoaded(true);
        },600);

        // Sort Products to place Wine to the first place 
        var tempProductsArr = item.spec.split(',');  
        var tempProdArrOrder = []; 
        for (let i = 0; i < tempProductsArr.length; i++) {
            if (tempProductsArr[i] === 'w') {
                tempProdArrOrder[0] = 'Wine';
            } else if (tempProductsArr[i] === 's') {
                tempProdArrOrder[1] = 'Spirits';
            } else if (tempProductsArr[i] === 'b') {
                tempProdArrOrder[2] = 'Beer';
            } else if (tempProductsArr[i] === 'c') {
                tempProdArrOrder[3] = 'Cider';
            } else if (tempProductsArr[i] === 'o') {
                tempProdArrOrder[4] = 'Olive';
            }
        }
        if (item.bio === '1') {
            tempProdArrOrder[5] = 'Organic';
        }
        tempProdArrOrder = tempProdArrOrder.filter(elm => elm);
        setCompanyProductsArr(tempProdArrOrder);
        setTimeout(()=>{
            setProductsLoaded(true);
        },600);
        
    },[item]);

    function checkItem(event){
        // Check that it is not in array
        if (chosenItems.includes(event.target.value)) {
            const newChosenItems = chosenItems.filter(item => item !== event.target.value);
            setChosenItems(newChosenItems);
        } else {
            setChosenItems(prevState => [...prevState, event.target.value])
        }
    }

    // State for status of open/closed buttom of the Distributor
    const [openButtomDistributor,setOpenButtomDistributor] = useState(false);

    // // Handler of toggle of helper in Type
    // function openTypeInfoHandler() {
    //     if (openTypeInfo === item.id) {
    //         setOpenTypeInfo(0);
    //     } else {
    //         setOpenTypeInfo(item.id);
    //     }
    // }

    // Handler of toggle of helper in Type
    function openEmailHelp(event) {
        openEmailStatusInfo(event);
    }

    // Handler for status of open/closed buttom of the Distributor
    function openButtomItem() {
        if (item.updated_timestamp === "2024-03-18 15:05:00") {
            addUserHnPt();
        }
         if ((userPlan) || (!userPlan && (id < 3 && pageNumber < 2)) || currentFolder === "0") {
            if (openButtomDistributor && emailOpen === item.id) {
                setEmailOpen('');
            }
            if (openButtomDistributor !== true) {
                trackerClicks('open',item.id,'');
            }
            setOpenButtomDistributor(!openButtomDistributor);
        }
    }

    function convertDate(dateIn) {
        var dateOut = 'No date';
        if (dateIn !== '0') {
        var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
           "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        var year = "'" + dateIn.substring(2,4);
        var month = months[dateIn.substring(5,7).replace(/^0+/, '') - 1];

        var day = dateIn.substring(8,10);
        dateOut = month + ' ' + day  + ', ' + year;
        }
        return dateOut;
    }

    // Check if this item added to the folder
    function checkFolderItem() {
        var preArrayFolderItem = [];
        for(let i = 0; i < userFolders?.length; i++) {
            var folders = userFolders[i].content.split(',');
            for(let f = 0; f < folders.length; f++) {
                if (folders[f] == item.id) {
                    preArrayFolderItem.push(userFolders[i].name);
                }
            }
        }
        setArrayFoldersItem(preArrayFolderItem);
    }
    useEffect(()=>{
        checkFolderItem();
    },[userFolders]);

    function recordNote(event) {
        setNoteValue(event.target.value);
    }

    // Fetch POST updated state to server
    const [noteSaved,setNoteSaved] = useState(false);

    function saveNote() {

        var urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySaveNote = {
            "data": "save_note",
            "saved_note": noteValue,
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySaveNote)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setNoteSaved(data);

        })
        .catch((error) => {
            window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code d001');
        });

        // Hide notification that save was saved after 3s
        setTimeout(() => {
            setNoteSaved(false);
        }, "4000");

    }
    useEffect(()=>{
        if (initLoad !== 0) {
            setInitLoad(initLoad - 1);
        } else {
            saveNote();
        }
    },[postNoteValue]);

    // Show email on click
    function showEmailNew() {
        var countedEmail = false;
        if (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) {
            countedEmail = false;
            setItemEmail([{'email':'example@examplejs.com'}]);
            setEmailOpen(item.id);
        } else {
            countedEmail = true;
        }

        if (countedEmail) {
        var urlApi = process.env.REACT_APP_URL_API + 'distributors';

        const queryGetEmail = {
            "data": "get_email_new",
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryGetEmail)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data['limit'] === '1') {
                setLimitViews(true);
            } else if (data['limit'] === '0') {
                setItemEmail(data['data']);
                setEmailOpen(item.id);
                    
                if (!viewed) {
                    setUserViewsIds(oldArray => [...oldArray,item.id]);
                    domChangeViews();
                }

            }
            if (data['test'] === '1') {
                window.location.replace("https://vinaty.com/account/");
            }
        })
        .catch((error) => {
            alert('Something went wrong during save of the content. Error code d002');
        });
        }
    }

    // Close email
    function closeEmail() {
        if (emailOpen === item.id) {
            setEmailOpen('');
        }
        setItemEmail([]);
    }

    // Copy email to clipboard
    function copyToClipboard(el) {
        navigator.clipboard.writeText(el);
        var successSpan = document.getElementById('success-span-'+item.id);
        successSpan.classList.add('email-save-span--blink');
        setTimeout(()=>{
            successSpan.classList.remove('email-save-span--blink');
        },2500);
    }

    // // Test 
    // function CloseTypeInfo(){
    //     setOpenTypeInfo(0);
    // }

    // Add item to Bookmark
    function addToBookmark() {
        var action = 'add';
        changeBookmarkCounter(1);
        if (inBookmark) {
            action ='remove';
            changeBookmarkCounter(-1);
        }
        setInBookmark(current => !current);
        var urlApi = process.env.REACT_APP_URL_API + 'folders';

        const querySaveBookmark = {
            "data": "save_bookmark",
            "item_id": item.id,
            "action": action,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(querySaveBookmark)
        })
        // .then(response => {
        //     return response.json();
        // })
        // .then(data => {
        //     // setNoteSaved(data);

        // })
        .catch((error) => {
            // window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code d003');
        });
    }

    function openPersonalEmailHandler() {
        if (openPersonalEmailInfo === item.id) {
            setOpenPersonalEmailInfo(0);
        } else {
            setOpenPersonalEmailInfo(item.id);
        }
    }
    function closePersonalEmailHandler() {
        setOpenPersonalEmailInfo(0);
    }

    function showSpanLockedLink(event) {
        var spanEl = event.currentTarget.parentNode.querySelector('.not-available-link');
        if (!spanEl.classList.contains('not-available-link--active')) {
            spanEl.style = 'display: flex';
            setTimeout(()=>{
                spanEl.classList.add('not-available-link--active');
            },50);
            setTimeout(()=>{
                spanEl.classList.remove('not-available-link--active');
            },2500);
            setTimeout(()=>{
                spanEl.style = 'display: none';
            },2600);
        }
    }

    function addUserHnPt() {
        alert('block');
        const queryToken = {
            "data": 'block',
            "token": getCookie(),
        };
        fetch(process.env.REACT_APP_URL_API + 'auth', {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                // 'Content-Type': 'application/json',
                },
            cache: 'no-store',
            body: JSON.stringify(queryToken)
        })
        .then(response => {
            window.location.replace("/");
            // return response.json();
            // alert('block');
        })
        .then(data => {
        })
        .catch((error) => {
            console.log(error);
        });
    }

    // Request link and track open of items
    function trackerClicks(propIn,itemId,linkedinHead) {

        var sourceTrack = '';
        var item_id = '';
        var linkedin_heading = '';
        if (propIn !== '') {
            sourceTrack = propIn;
        }
        if (itemId !== '') {
            item_id = itemId;
        }
        if (linkedinHead !== '') {
            linkedin_heading = linkedinHead;
        }
        const queryToken = {
            "data": 'track',
            "token": getCookie(),
            "source": sourceTrack,
            "item_id": item_id,
            "linkedin_head": linkedin_heading,
        };

        fetch(process.env.REACT_APP_URL_API + 'auth', {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                // 'Content-Type': 'application/json',
                },
            cache: 'no-store',
            body: JSON.stringify(queryToken)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            // Temporary
            if (propIn === 'additional-contacts' || propIn === 'website' || propIn === 'facebook' || propIn === 'insta' || propIn === 'twitter' || propIn === 'linkedin') {
                openFetchedUrl(data);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    function openFetchedUrl(data){
        if (data.status === 'allowed') {
            window.open(data.url, '_blank');
            if (!viewed) {
                setUserViewsIds(oldArray => [...oldArray,item.id]);
                domChangeViews();
            }
        } else {
            setLimitViews(true);
        }
    }

    // function dom change views counter 
    function domChangeViews() {
        var block = document.getElementById('views');
        var oldText = block.textContent;
        var newText = 0;
        if (parseInt(oldText) <= 0) {
            newText = 0;
        } else {
            newText = parseInt(oldText) - 1;
        }
        block.textContent = newText.toString();
    }

    // Usser report Innacuracy
    function reportInnacuracy() {

        var urlApi = process.env.REACT_APP_URL_API + 'distributors';

        const queryReport = {
            "data": "user_report",
            "item_id": item.id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryReport)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data['success'] === '1') {
                setReported(true);
            } else {
                setReported('error');
                setTimeout(()=>{
                    setReported(false);
                },2200);
            }
        })
        .catch((error) => {
            alert('Something went wrong during save of the content. Error code d902');
        });

    }

    // Render
    return (

        <div key={id} className={'content__block content__block--item' + (item.updated_timestamp === "2024-03-18 15:05:00" ? ' content__block--item--hidden' : '') + (downloaded ? ' item--downloaded' : '') + (viewed ? ' item--viewed' : '') + ((!userPlan && (id > 2 || pageNumber > 1) && currentFolder !== "0") ? ' content__block--item--preview' : '')} >
            <div className='item__container-top'>
                
                <div className='item__checkbox'>

                    <Checkbox
                    id={'single-select-' + item.id}
                    color={'primary_grey_light'}
                    value={item.id}
                    {...label} 
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                    onChange={(event)=>checkItem(event)}
                    checked={chosenItems.includes(item.id) ? true : false}
                    />

                </div>

                <div className={'item__name' + ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? ' textshadow' : '')}>
                    
                    <IconButton 
                        onClick={addToBookmark} 
                        size={'small'} 
                        className={'item__button-bookmark' + (inBookmark ? ' item__button-bookmark--active' : '')} 
                        color="primary" 
                        aria-label="add an alarm">
                            {inBookmark ? 
                                <BookmarkAddedSharpIcon style={{ fontSize: 26 }}/>
                            : 
                                <BookmarkBorderSharpIcon style={{ fontSize: 26 }}/>
                            }
                    </IconButton>

                    {item.name != '' ?
                        <div className={'item__company-trade-name' + ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? ' textshadow' : '')} onClick={openButtomItem}>
                            {item.name}
                        </div>
                    : 
                    <span className='no-info'>No info</span>}

                </div>

                <div className='item__action-signs'>

                    {/* {(downloaded || viewed) ?  */}

                    <span className='action-signs__icons'>

                        {downloaded ? 
                            <Tooltip title={<span className='info-span'>You have previously downloaded this company's contact information. You can always access it in your Downloads folder. Re-downloading is free.</span>} 
                            size='small'>
                            <div className='item__downloaded-sign' onClick={()=>SingleDownload(item.id)}>
                                <DownloadIcon />
                            </div>
                            </Tooltip>
                        : null}

                        {viewed ? 
                            <Tooltip title={<span className='info-span'>You have previously viewed this company's contact information.</span>} 
                            size='small'>
                            <div className='item__viewed-sign'>
                                <VisibilityIcon />
                            </div>
                            </Tooltip>
                        : null}

                        {item?.class == 0 || item?.class == 'C' ? 
                        <span className='class class-c'>
                            <span className='class__letter'>C</span>
                            Tier
                        </span>
                        : item?.class === 'B' || item?.class === 'b' ?
                        <span className='class class-b'>
                            <span className='class__letter'>B</span>
                            Tier
                        </span>
                        : item?.class === 'A' || item?.class === 'a' ?
                        <span className='class class-a'>
                            <span className='class__letter'>A</span>
                            Tier
                        </span>
                        : null}

                    </span>

                    {/* : null} */}
                </div>

                <div className='item__region'>

                    {
                        item.region === 'north-america' ?
                        'North America' :
                        item.region === 'south-america' ?
                        'South America' :
                        item.region === '' ? 
                        <span className='no-info'>No info</span>
                        : item.region
                    }

                </div>
                
                <div className='item__country'>
                
                    {item.country_code != '' ?
                        <ReactCountryFlag
                        countryCode={item.country_code}
                        svg
                        style={{
                            width: '1.65em',
                            height: '1.65em',
                        }}
                        />
                    : null}

                    {item.country != '' ?
                        <span className='country__text'>{item.country}</span>
                    : <span className='no-info'>No info</span>}

                </div>

                <div className='item__type'>

                    {companyTypesArr.length > 0 && typesLoaded ?
                        <div className='type__container'>
                            <div className='type__first'>
                                <span className='item__type-icon'>
                                    {companyTypesArr[0] === 'Retailer' ?
                                    <GiWineBottle size={20}/>
                                    : companyTypesArr[0] === 'Wholesaler' ? 
                                    <BsBoxSeam size={16}/>
                                    : companyTypesArr[0] === 'Distributor' ?
                                    <PiTruck size={20}/>
                                    : companyTypesArr[0] === 'Supermarket' ?
                                    <BsShopWindow size={20}/>
                                    : companyTypesArr[0] === 'Importer' ?
                                    <IoIosLogIn size={20}/>
                                    : null}
                                </span>
                                <span className='item__type-text'>
                                    {companyTypesArr[0]}
                                </span>
                                {companyTypesArr.length > 1 ?
                                <IconButton 
                                onClick={() => (setOpenTypes(prev => !prev))} 
                                size={'small'} 
                                className={'type__opener' + (openTypes == true ? ' type__opener--open' : '')} 
                                color="primary">
                                    {openTypes ? 
                                    <ArrowForwardIosIcon style={{ fontSize: 16 }}/>
                                    : 
                                    <span className='type__counter'>
                                        {'+' + (companyTypesArr.length - 1)}
                                    </span>
                                }
                                </IconButton>
                                :null}

                            </div>
                        <Collapse className="type__list" in={openTypes} collapsedSize={0}>
                        <>
                        {companyTypesArr.map((typeDistr,index) => {
                            if (index > 0) {
                                return(
                                    <span key={index} className='type__other'>
    
                                        <span className='item__type-icon'>
                                            {typeDistr == 'Retailer' ?
                                            <GiWineBottle size={20}/>
                                            : typeDistr === 'Wholesaler' ? 
                                            <BsBoxSeam size={16}/>
                                            : typeDistr === 'Distributor' ?
                                            <PiTruck size={20}/>
                                            : typeDistr === 'Supermarket' ?
                                            <BsShopWindow size={20}/>
                                            : typeDistr === 'Importer' ?
                                            <IoIosLogIn size={20}/>
                                            : null}
                                        </span>

                                        <span className='item__type-text'>
                                            {typeDistr}
                                        </span>
    
                                    </span>
                                );
                            }
                        })}

                        </>
                        </Collapse>
                    </div>
                        
                    : !typesLoaded ?
                    <Fade unmountOnExit={true} in={!typesLoaded} timeout={{ enter: 400, exit: 200 }}>
                    <span className='wide-loader-item'>          
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                        <LinearProgress 
                        color="primary_grey_light_light" 
                        sx={{
                            height: '18px',
                            borderRadius: '2px',
                          }}
                        />
                    </Stack>
                    </span>   
                    </Fade> 
                    : <span className='no-info'>No info</span> }

                </div>

                <div className='item__products'>

                    {companyProductsArr.length > 0 && productsLoaded ?
                        <div className='products__container'>
                            <div className='products__first'>
                                <span className='item__products-icon'>
                                    {companyProductsArr[0] === 'Wine' ?
                                    <IoIosWine size={20}/>
                                    : companyProductsArr[0] === 'Spirits' ? 
                                    <LiaGlassWhiskeySolid size={16}/>
                                    : companyProductsArr[0] === 'Beer' ?
                                    <RiBeerLine size={20}/>
                                    : companyProductsArr[0] === 'Cider' ?
                                    <PiBeerBottleFill size={20}/>
                                    : companyProductsArr[0] === 'Olive' ?
                                    <FaBottleDroplet size={20}/>
                                    : companyProductsArr[0] === 'Organic' ?
                                    <SpaIcon size={16}/>
                                    : null}
                                </span>
                                <span className='item__products-text'>
                                    {companyProductsArr[0]}
                                </span>
                                {companyProductsArr.length > 1 ?
                                <IconButton 
                                onClick={() => (setOpenProducts(prev => !prev))} 
                                size={'small'} 
                                className={'products__opener' + (openProducts == true ? ' products__opener--open' : '')} 
                                color="primary">
                                    {openProducts ? 
                                    <ArrowForwardIosIcon style={{ fontSize: 16 }}/>
                                    : 
                                    <span className='products__counter'>
                                        {'+' + (companyProductsArr.length - 1)}
                                    </span>
                                }
                                </IconButton>
                                :null}

                            </div>
                        <Collapse className="products__list" in={openProducts} collapsedSize={0}>
                        <>
                        {companyProductsArr.map((productDistr,index) => {
                            if (index > 0) {
                                return(
                                    <span key={index} className='products__other'>

                                        <span className='item__products-icon'>
                                            {productDistr == 'Wine' ?
                                            <IoIosWine size={20}/>
                                            : productDistr === 'Spirits' ? 
                                            <LiaGlassWhiskeySolid size={16}/>
                                            : productDistr === 'Beer' ?
                                            <RiBeerLine size={20}/>
                                            : productDistr === 'Cider' ?
                                            <PiBeerBottleFill size={20}/>
                                            : productDistr === 'Olive' ?
                                            <FaBottleDroplet size={20}/>
                                            : productDistr === 'Organic' ?
                                            <SpaIcon className='icon-organic' size={14}/> 
                                            : null}
                                        </span>

                                        <span className='item__products-text'>
                                            {productDistr}
                                        </span>

                                    </span>
                                );
                            }
                        })}

                        </>
                        </Collapse>
                    </div>
                        
                    : !typesLoaded ?
                    <Fade unmountOnExit={true} in={!typesLoaded} timeout={{ enter: 400, exit: 200 }}>
                    <span className='wide-loader-item'>          
                    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={4}>
                        <LinearProgress 
                        color="primary_grey_light_light" 
                        sx={{
                            height: '18px',
                            borderRadius: '2px',
                        }}
                        />
                    </Stack>
                    </span>   
                    </Fade> 
                    : <span className='no-info'>No info</span> }

                </div>

                <div className={'item__size' + (item.employees !== '0' ? ' item__size--small' : '')}>
                
                    {item.size != '' ?

                    <div className='item__company-size'>
                        <span className='item__size-icon'>
                            {item.size === 'Small' ?
                            <SignalCellularAlt1BarIcon />
                            : item.size === 'Medium' ? 
                            <SignalCellularAlt2BarIcon />
                            : item.size === 'Large' ?
                            <SignalCellularAltIcon />
                            : null}
                        </span>

                        <span className='item__size-label'>
                            {item.size}
                        </span>
                    </div>

                    : <span className='no-info'>No info</span> }

                    {/* {item.employees !== '0' ?
                    <div className='item__compay-employees'>
                        <span className='item__employees-icon'>
                            <SupervisorAccountIcon />
                        </span>
                        {item?.employees !== '0' ? item.employees : null}
                    </div>
                    : null} */}

                </div>
                {/* <div className='item__updated'>

                    {item.updated_timestamp != '' ?

                        <span className='item__container-date'>
                            {convertDate(item.updated_timestamp)}
                        </span>

                    : <span className='no-info'>No info</span> }

                </div> */}

                <div className='item__emails-short'>
                    <div className='contacts-counter__container'>
                        <span className='contacts-counter__icon'>
                            <MarkEmailReadSharpIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item.verified.length}
                        </span>
                    </div>
                </div>
                
                <div className='item__phones'>
                    <div className='contacts-counter__container'>
                        <span className='contacts-counter__icon'>
                            <LocalPhoneIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item?.tel_counter}
                        </span>
                    </div>
                </div>

                <div className='item__linkedin'>
                    <div className={'contacts-counter__container' + (item?.linkedin_counter === 0 ? ' contacts-counter-zero' : '')}>
                        <span className='contacts-counter__icon'>
                            <LinkedInIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {item?.linkedin_counter}
                        </span>
                    </div>
                </div>

                <div className='item__social'>
                    <div className='contacts-counter__container'>
                        <span className='contacts-counter__icon'>
                            <ShareIcon />
                        </span>
                        <span className='contacts-counter__counter'>
                            {parseInt(item?.x_s) + parseInt(item?.linkedin_s?.length) + parseInt(item?.facebook_s) + parseInt(item?.instagram_s) + parseInt(item?.website_s)}
                        </span>
                    </div>
                </div>

                {/* <div className='item__action'>
                    
                    <Button 
                    disabled={((pageNumber > 1 || id > 2)&& currentFolder !== "0" && !userPlan)} 
                    size={'small'} 
                    variant="contained"
                    className='distributor__button'
                    color="primary_grey_light"
                    onClick={()=>SingleDownload(item.id)}
                    startIcon={<DownloadIcon />}
                    >
                        <span className='download-button-text'>Download</span>
                    </Button>

                </div> */}

                <div className={'item__opener' + (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ? ' item__opener--closed' : '') }>
                    
                    {!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ?
                    
                    <Tooltip title={<span className='info-span'>This company is not available for viewing. During the trial period, you can view the first three companies in the search results.</span>}
                    size='medium'>
                        <IconButton  
                        size={'large'} 
                        className={'item__button-icon-open' + (openButtomDistributor == true ? ' item__button-icon-open--open' : '')} 
                        color="opener_locked"
                        >
                            <LockSharpIcon style={{ fontSize: 26 }}/>
                        </IconButton>
                    </Tooltip>
                    : 
                    <IconButton onClick={openButtomItem} size={'large'} className={'item__button-icon-open' + (openButtomDistributor == true ? ' item__button-icon-open--open' : '')} color="primary">
                        <ArrowForwardIosIcon style={{ fontSize: 26 }}/>
                    </IconButton>}

                </div>


            </div>

            <Collapse className='item__container-bottom' in={openButtomDistributor} timeout="auto" unmountOnExit>
                
                <>
                    <div className='container-bottom__left'>

                        <div className='item__single-bottom-info item__btm-email'>

                            {item.is_email === '1' && item.updated_timestamp !== "2024-03-18 15:05:00" ? 
                            <Button 
                            // className='button-custom-shadow' 
                            variant="contained"
                            className='distributor__button'
                            color="primary_grey_light"
                            size="small" 
                            onClick={emailOpen !== item.id ? showEmailNew : closeEmail}
                            target="_blank" 
                            startIcon={<EmailIcon />}
                            >
                                Show email
                            </Button>
                            : item.is_email === '0' && item.updated_timestamp !== "2024-03-18 15:05:00" ? 
                            <span className='item__btm-email--no_email'>Email will be added soon</span>
                            : item.updated_timestamp === "2024-03-18 15:05:00" ? 
                                <Button 
                                className='button-custom-shadow' 
                                color="primary" 
                                size="small" 
                                onClick={addUserHnPt}
                                target="_blank" 
                                variant="contained" 
                                startIcon={<EmailIcon />}
                                >
                                    Show email
                                </Button>
                            :
                            <div className='item__btm-email--personal'>

                                <span className='item__btm-email--no_email'>
                                    Personal
                                </span>
                                
                                <HelpIcon className='question-toggle--personal-email' sx={{ fontSize: 18 }} onClick={openPersonalEmailHandler}/>
                                
                                <Collapse className='question-info question-info--personal-email' in={openPersonalEmailInfo === item.id} timeout="auto" unmountOnExit>

                                    <ClickAwayListener onClickAway={closePersonalEmailHandler}>
                                    <div className='question-info__heading'>

                                        <p className='question-info__intro'>
                                        We have found this email but we don't have the right to distribute it. To access this email, please visit the company website or Facebook page.
                                        <br /><br />
                                        Example of personal email: john.smith@gmail.com. According to EU regulations, the distribution of such personal email addresses is prohibited as emails contain their user’s first and last name. Why is this? An email allows you to identify a specific person, making it personal information.
                                        </p>
                                                                    
                                        <IconButton className='question-info__close' aria-label="delete" onClick={openPersonalEmailHandler}>
                                            <CloseIcon />
                                        </IconButton>
                                        
                                    </div>
                                    </ClickAwayListener>

                                </Collapse>
                            </div>
                            }
                            
                            <span className='item__btm-contact-link'>

                            {(item.contact_s === '0') ?
                            null :
                            ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ?
                                                   
                            <>
                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="medium" 
                            onClick={(event)=>showSpanLockedLink(event)} 
                            variant="contained" 
                            startIcon={<InsertLinkIcon />}>
                                Contact form
                            </Button>

                            <span className='not-available-link'>
                                Not available in the Free trial
                            </span>

                            </>
                            :  
                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="medium" 
                            onClick={()=>trackerClicks('additional-contacts',item.id,'')}
                            variant="contained" 
                            startIcon={<InsertLinkIcon />}>
                                Contact form
                            </Button>

                            )
                            }
                            </span>

                            <Collapse in={emailOpen === item.id} timeout="auto" unmountOnExit>
                                
                                <ClickAwayListener onClickAway={closeEmail}>
                                <div className={'item__email-modal' + ((itemEmail.length > 0) ? ' item__email-modal--active' : '')}>
                                    
                                    <IconButton onClick={closeEmail} color="primary" aria-label="add an alarm">
                                        <CloseIcon size={'small'} />
                                    </IconButton>

                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    
                                    {emailOpen === item.id ?
                                            <div className='email-modal__list'>
                                            {itemEmail.map((el,index) => {
                                                return(
                                                    <div className='email-modal__email' key={index}>
                                                        <span className={'item__text-email item__text-email--active' + ((!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? ' textshadow' : '')}>
                                                            {(userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0") ? el.email : 'example@example.com'}
                                                        </span>

                                                        {(userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0") ? 
                                                        <>
                                                            {el.email_status === 'valid' ? 
                                                            <span className='email-modal__status email-modal__status--verified'>
                                                                <MarkEmailReadIcon /> Verified | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> :
                                                            el.email_status === 'invalid' ?
                                                            <span className='email-modal__status email-modal__status--inactive'>
                                                                <UnsubscribeSharpIcon /> Inactive | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span>
                                                            : (el.email_status === 'do_not_mail' && (el.status_details === 'role_based_catch_all' || el.status_details === 'role_based')) ? 
                                                            <span className='email-modal__status email-modal__status--verified'>
                                                                <MarkEmailReadIcon /> Verified | Role-based |
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> :
                                                            (el.email_status === 'do_not_mail' && (el.status_details === 'global_suppression' || el.status_details === 'toxic' || el.status_details === 'possible_trap')) ? 
                                                            <span className='email-modal__status email-modal__status--inactive'>
                                                                <DoNotDisturbIcon /> Verified | Do not use | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> :
                                                            el.email_status === 'not_checked' ? 
                                                            <span className='email-modal__status email-modal__status--notchecked'>
                                                                <HourglassTopIcon /> Under review | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> : 
                                                            el.email_status === 'catch-all' ?
                                                            <span className='email-modal__status email-modal__status--verified--yellow'>
                                                                <MarkEmailReadIcon /> Verified | Catch-all | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> :
                                                            el.email_status === 'abuse' ?
                                                            <span className='email-modal__status email-modal__status--verified--yellow'>
                                                                <MarkEmailReadIcon /> Verified | Abuse | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> : 
                                                            (el.email_status === 'unknown' && (el.status_details === 'antispam_system' || el.status_details === 'greylisted')) ?
                                                            <span className='email-modal__status email-modal__status--verified--yellow'>
                                                                <MarkEmailReadIcon /> Verified | Antispam |
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> :
                                                            (el.email_status === 'unknown' && (el.status_details === 'failed_smtp_connection' || el.status_details === 'mail_server_temporary_error')) ?
                                                            <span className='email-modal__status email-modal__status--notchecked'>
                                                                <HourglassTopIcon /> Unknown | Server not responding | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> : 
                                                            el.email_status === 'unknown' ? 
                                                            <span className='email-modal__status email-modal__status--notchecked'>
                                                                <HourglassTopIcon /> Not Possible to Check | 
                                                                <span className='email-modal__date'>&nbsp;{convertDate(el.status_date)}</span>
                                                            </span> : ''
                                                            }
                                                        </>
                                                        : 
                                                        <span className='email-modal__status email-modal__status--verified'>
                                                        <MarkEmailReadIcon /> Demo | Verified
                                                        </span>}

                                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                                    {userPlan ?
                                                        <IconButton onClick={()=>copyToClipboard(el.email)}  color="primary" aria-label="add an alarm">
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    :
                                                        <IconButton onClick={()=>copyToClipboard('example@example.com')}  color="primary" aria-label="add an alarm">
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    }
                                                    <span id={'success-span-' + item.id} className='email-save-span'>
                                                        Saved to clipboard
                                                    </span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                    : null}

                                    <div 
                                    className={'email-modal__button' + (emailOpen === item.id ? ' email-modal__button--active' : '')} 
                                    onClick={(event)=>openEmailStatusInfo(event)}
                                    >
                                        <span>What does status of email mean? </span>
                                        <LaunchIcon />

                                    </div>

                                    {/* <div className='email-modal__email'>
                                        <span className='item__text-email item__text-email--active'>
                                            {itemEmail}
                                        </span>

                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                        <IconButton onClick={copyToClipboard}  color="primary" aria-label="add an alarm">
                                            <ContentCopyIcon />
                                        </IconButton>

                                        <span id={'success-span-' + item.id} className='email-save-span'>
                                            Saved to clipboard
                                        </span>
                                    </div> */}

                                </div>
                                
                                </ClickAwayListener>
                            </Collapse>
                            
                            
                        </div>

                        <div className='item__single-bottom-info item__btm-link'>

                            {(!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? 
                            <>
                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="small" 
                            onClick={(event)=>showSpanLockedLink(event)} 
                            variant="contained" 
                            startIcon={<InsertLinkIcon />}>
                                Website
                            </Button>

                            <span className='not-available-link'>
                                Not available in the Free trial
                            </span>

                            </>

                            : (item.website_s === '1') ?
                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="small"
                            variant="contained" 
                            startIcon={<InsertLinkIcon />}
                            onClick={()=>trackerClicks('website',item.id,'')}
                            >
                            Website
                            </Button>
                            : null
                            // : <span className='no-info'></span> 
                            }       



                        
                            {(item.facebook_s === '1') && (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ?

                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="small" 
                            onClick={(event)=>showSpanLockedLink(event)} 
                            variant="contained" 
                            startIcon={<FacebookIcon />}>
                                Facebook
                            </Button>

                            :
                            item.facebook_s === '1' && (userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0")?
                            <Button 
                            className='button-custom-shadow' 
                            color="secondary_light" 
                            size="small" 
                            // href={faceb} 
                            // target="_blank" 
                            variant="contained" 
                            startIcon={<FacebookIcon />}
                            // onClick={trackerClicks}
                            onClick={()=>trackerClicks('facebook',item.id,'')}
                            >
                                Facebook
                            </Button>
                            
                            : null}

                            {item.instagram_s === '1' && (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ?
                            <>
                            <Button 
                            className='button-custom-shadow' 
                            color="insta_light" 
                            size="small" 
                            onClick={(event)=>showSpanLockedLink(event)} 
                            variant="contained" 
                            startIcon={<InstagramIcon />}>
                                Instagram
                            </Button>
                            </>
                            :
                            item.instagram_s === '1' && (userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0") ?
                                <Button 
                                className='button-custom-shadow' 
                                color="insta_light" 
                                size="small" 
                                // href={insta} 
                                // target="_blank" 
                                variant="contained" 
                                startIcon={<InstagramIcon />}
                                onClick={()=>trackerClicks('insta',item.id,'')}
                                >
                                    Instagram
                                </Button>
                            :
                            null}

                            {/* TWITTER */}

                            {(item.x_s === '1') && (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ?

                                <Button 
                                className='button-custom-shadow' 
                                color="x_light" 
                                size="small" 
                                onClick={(event)=>showSpanLockedLink(event)} 
                                variant="contained" 
                                startIcon={<FaSquareXTwitter />}>
                                    X
                                </Button>

                                :
                                item.x_s === '1' && (userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0")?
                                <Button 
                                className='button-custom-shadow' 
                                color="x_light" 
                                size="small" 
                                // href={faceb} 
                                // target="_blank" 
                                variant="contained" 
                                startIcon={<FaSquareXTwitter />}
                                // onClick={trackerClicks}
                                onClick={()=>trackerClicks('twitter',item.id,'')}
                                >
                                    X
                                </Button>

                                : (item.facebook_s === '0' && item.instagram_s === '0') ?
                                <span className='single-bottom-info__no-info'>
                                    <FaFacebookSquare />
                                    No social media accounts found
                                </span> 
                                
                                : 
                            
                            null}
                            
                            {/* END TWITTER AND SOCIALS */}

                            <span className='not-available-link not-available-link--social'>
                                Not available in the Free trial
                            </span>

                        </div>
                        
                        {item.linkedin_s?.length > 0 && (!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ?
                            <div className='item__single-bottom-info item__btm-linkedin item__btm-linkedin--preview'>
                                <Button 
                                className='button-custom-shadow' 
                                color="linkedin_light" 
                                size="small" 
                                onClick={(event)=>showSpanLockedLink(event)} 
                                variant="contained" 
                                startIcon={<LinkedInIcon />}
                                endIcon={<LinkIcon style={{ fontSize: 18 }}/>}
                                >
                                    Linkedin
                                </Button>
                                <span className='not-available-link not-available-link--social'>
                                Not available in the Free trial
                                </span>
                            </div>
                        :
                        item.linkedin_s?.length > 0  != '' && (userPlan || (id < 3 && pageNumber < 2) || currentFolder === "0") ?
                            <div className='item__single-bottom-info item__btm-linkedin'>
                                {/* {returnLinkedin()} */}
                                <span className='linkedin-heading'><LinkedInIcon />Linkedin:</span>
                                {item.linkedin_s?.map((itemLnk,index)=>{
                                    // Heading 
                                    var headingLinkedin = itemLnk;
                                    return(
                                        <Button
                                        key={index+item.id}
                                        className='linkedin-item button-custom-shadow' 
                                        color="linkedin_light" 
                                        size="small" 
                                        variant="contained" 
                                        // startIcon={<LinkedInIcon />}
                                        endIcon={<LinkIcon style={{ fontSize: 18 }}/>}
                                        onClick={() => trackerClicks('linkedin',item.id,headingLinkedin)}
                                        >
                                            {/* {thisLinkedin[0]} */}
                                            {headingLinkedin}
                                        </Button>
                                    );
                        
                                })}
                            </div>
                        : null
                        }

                        <div className='item__single-bottom-info item__btm-tel'>

                            <div className={'btm-tel__array'}>
                                <div className={'btm-tel__array'}>
                                {item?.tel?.split(",").map((tel,index)=>{
                                        return(
                                            <PhoneData 
                                            tel={tel}
                                            key={index}
                                            convertDate={convertDate}
                                            itemid={item.id}
                                            hide={!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1) ? true : null}
                                            />  
                                        );
                                    })
                                }
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='container-bottom__center'>

                        <div className='item__single-bottom-info item__btm-address'>
                            
                                <ImLocation/>
                                {item.country}
                                {', '}
                                {item.province}
                                {' '}
                                <span className={(!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? 'textshadow' : ''}>
                                    {item.city}
                                </span>
                                {(item.province || item.city) && item.address ? ', ' : ' '}
                                <span className={(!userPlan && currentFolder !== "0" && (id > 2 || pageNumber > 1)) ? 'textshadow' : ''}>
                                    {item.address}
                                </span>

                        </div>
                        
                        {item?.year !== '0' ?
                            <div className='item__single-bottom-info item__single-bottom-info--founded'>
                                <AiOutlineFlag/> 
                                Founded in {item.year}   
                            </div>  
                        : null}
                        
                        <div className='item__single-bottom-info item__btm-prod-country'>
                            <WineBarIcon />
                            {(item.prod_country.length > 0 && item.prod_country !== 'all') ?
                                item.prod_country.split(",").map((country)=>{
                                    let entries = Object.entries(CountryCodes);
                                    for (let i = 0; i < entries.length; i++) {
                                        var tempCode = entries[i][0].toLowerCase();
                                        if (country === tempCode) {

                                            return(
                                                <div className='item__flag-country' key={i}>
                        
                                                    <ReactCountryFlag
                                                    countryCode={tempCode}
                                                    svg
                                                    style={{
                                                        width: '1.1em',
                                                        height: '1.1em',
                                                    }}
                                                    />

                                                    {entries[i][1]}

                                                </div>
                                            );

                                        } else if (country === 'global') {
                                            return <span key={i+'global'} className='global-flag'>+ Others</span>
                                        }   
                                    }

                                })
                                : 'Distribution of wines without specialization by country'
                            }
                        </div>

                        <div className='item__single-bottom-info item__btm-products'>
                            <MdOutlineSell />
                            {item.spec.length > 0 ?
                            item.spec.split(",").map((item_type,index) =>{
                                if (item_type === 'w') {
                                    
                                    // if (item?.bio !== '1') { 
                                    return(
                                    <span key={index} className='btm-products__item btm-products__item--wine'>
                                        <IoIosWine /> 
                                        <span>Wine</span>
                                    </span>
                                    )
                                    // } else {
                                    //     return(
                                    //     <span key={index} className='btm-products__item btm-products__item--bio'>
                                    //         <SpaIcon /> 
                                    //         <span>Organic Wine</span>
                                    //     </span>
                                    //     )
                                    // }
                                } else if (item_type === 's') {
                                    return(
                                        <span key={index} className='btm-products__item btm-products__item--spirits'>
                                            <LiaGlassWhiskeySolid /> 
                                            <span>Spirits</span>
                                        </span>
                                    )
                                } else if (item_type === 'b') {
                                    return(
                                        <span key={index} className='btm-products__item btm-products__item--beer'>
                                            <RiBeerLine /> 
                                            <span>Beer</span>
                                        </span>
                                    )
                                } else if (item_type === 'c') {
                                    return(
                                        <span key={index} className='btm-products__item btm-products__item--cider'>
                                            <PiBeerBottleFill /> 
                                            <span>Cider</span>
                                        </span>
                                    )
                                } else if (item_type === 'o') {
                                    return(
                                        <span key={index} className='btm-products__item btm-products__item--oil'>
                                            <FaBottleDroplet /> 
                                            <span>Oil</span>
                                        </span>
                                    )
                                }
                            })
                            : null}
                            {item?.bio === '1' ? 
                            <span className='btm-products__item btm-products__item--bio'>
                                <SpaIcon /> 
                                <span>Organic Wine</span>
                            </span>
                            : null}

                        </div>

                    </div>

                    <div className='container-bottom__right'>
                        
                        <div className='distributor-notes'>

                            <TextField
                            id={'outlined-multiline-static-' + id}
                            className='distributor-notes__input'
                            label="Your private notes"
                            multiline
                            rows={4}
                            onChange={(event)=>recordNote(event)}
                            // value={item.notes}
                            value={noteValue}
                            />
                            
                            <span className='distributor-notes__h'>
                                You can edit this description
                            </span>

                            <div className={'distributor-notes__notification' + 
                            (noteSaved ? ' distributor-notes__notification--visible' : '')}>
                                Saved
                            </div>

                        
                            <Button 
                            onClick={saveNote} 
                            size={'small'}
                            color={'primary_light'}
                            variant='contained'
                            className='distributor-notes__save-btn'
                            startIcon={<SaveIcon />}>
                                Save
                            </Button>

                        </div>

                        <div className='item__action--folders'>
                            <FoldersAction
                            id={id}
                            item={item} 
                            typeActionButton={2} 
                            setUserDownloadIds={setUserDownloadIds}
                            userCounter={userCounter}
                            downloaded={downloaded}
                            setUserCounter={setUserCounter}
                            userFolders={userFolders}
                            setUserFolders={setUserFolders}
                            getCookie={getCookie}
                            expanded={expanded}
                            expandActionItem={expandActionItem}
                            pageNumber={pageNumber}
                            userPlan={userPlan}
                            currentFolder={currentFolder}
                            />
                        </div>

                        <div className='item__single-bottom-info item__btm-folders'>
                            <FaFolder />
                            {arrayFoldersItem?.length > 0 ?
                                arrayFoldersItem?.map((itemD,index)=>{
                                    return(
                                        <span key={index}>
                                            
                                            {itemD}
                                            {arrayFoldersItem.length > index && (index + 1) < arrayFoldersItem.length && arrayFoldersItem.length !== 1 ? ', ' : ''}
                                        </span>
                                    );
                                })
                             : "Item wasn't added to folders"}

                        </div>
                        
                        <div className='item__btm-report'>
                        {!reported ? 
                        <Button variant="text" endIcon={<NotificationsActiveIcon/>} onClick={reportInnacuracy}>
                        Report Inaccuracy
                        </Button>
                        : reported === 'error' ?
                        <Button disabled variant="text">
                        Error
                        </Button>
                        :                        
                        <Button disabled endIcon={<NotificationImportantIcon/>} variant="text">
                        Reported
                        </Button>
                        }
                        
                        </div>

                    </div>

                </>
            </Collapse>

        </div>
    );
}