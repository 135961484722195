import React, { useEffect, useState } from 'react';

// import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';

export default function DbUsers(props) {

    const {users,getCookie} = props;

    // Filter Active
    const [filterActive,setFilterActive] = useState(true);

    // Block user
    // const [userBlock,setUserBlock] = useState(false);

    // Filter active 
    function filterActiveFn() {
        setFilterActive((prev)=>(!prev));
    }

    var m = new Date();
    var dateString =
        m.getUTCFullYear() + "/" +
        ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
        ("0" + m.getUTCDate()).slice(-2) + " " +
        ("0" + m.getUTCHours()).slice(-2) + ":" +
        ("0" + m.getUTCMinutes()).slice(-2) + ":" +
        ("0" + m.getUTCSeconds()).slice(-2);

    function viewsMaxFunc(stts) {
        if (stts === '2' || stts === '6' || stts === '7' || stts === '10') {
            return '2000'
        } else {
             return '25'
        }
    }

    function endDateCalc(start,plan,paidPlan) {
        var days = 3;
        if (plan === '6' || paidPlan === '6') {
            days = 90;
        } else if (plan === '7' || paidPlan === '7') {
            days = 180;
        } else if (plan === '2' || paidPlan === '2') {
            days = 365;
        } else if (plan === '10' || paidPlan === '10') {
            days = 30;
        }

        var result = new Date(start);
        result.setDate(result.getDate() + days);
        var endDate =
        result.getUTCFullYear() + "-" +
        ("0" + (result.getUTCMonth()+1)).slice(-2) + "-" +
        ("0" + result.getUTCDate()).slice(-2) + " " +
        ("0" + result.getUTCHours()).slice(-2) + ":" +
        ("0" + result.getUTCMinutes()).slice(-2) + ":" +
        ("0" + result.getUTCSeconds()).slice(-2);

        var checkDate = endDate === 'NaN-aN-aN aN:aN:aN' ? '' : endDate;
        // var endDate = result;
        return checkDate;
    }
    function downloadDebt(item) {
        var credits = 500;
        // User credits counted 500,750,1000
        if (item.id < 577) {
            if (item.status === '6' || item.paid_plan === '6') {
                credits = 500;
            } else if (item.status === '7' || item.paid_plan === '7') {
                credits = 750;
            } else if (item.status === '2' || item.paid_plan === '2') {
                credits = 1000;
            } else if (item.status === '10' || item.paid_plan === '10') {
                credits = 500;
            }
        }

        if (item?.paid_date !== undefined) {
            var datePaid = new Date(item?.paid_date).getTime() / 1000;

            // Change date from current to end
            var currentTimestamp = '';
            if (item.status === '1') {
                currentTimestamp = new Date(endDateCalc(item.paid_date,item.status,item.paid_plan)).getTime().toString().slice(0,10);
            } else {
                currentTimestamp = new Date().getTime().toString().slice(0,10);
            }
            var debt = (Math.floor((currentTimestamp - datePaid)/(3600*24)/30)+1)*credits;
            return(debt);
        }
        
        return('');
    }

    function openBlocker(id) {
        var thisBlocker = document.getElementById('block-'+id);
        if (thisBlocker.classList.contains('blocker-item--hidden')) {
            thisBlocker.classList.remove('blocker-item--hidden');
        } else {
            thisBlocker.classList.add('blocker-item--hidden');
        }
    }

    function blockUser(id) {

        var urlApi = process.env.REACT_APP_URL_API + 'dashboard';

        const queryBlockUser = {
            "data": "block_user",
            "user_id": id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryBlockUser)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data === 'done') {
                openBlocker(id);
            }
        })
        .catch((error) => {
            // window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code dashboard001');
        });
    }

    function updateCredits(userId) {
        var urlApi = process.env.REACT_APP_URL_API + 'dashboard';

        const queryBlockUser = {
            "data": "update_credits",
            "user_id": userId,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryBlockUser)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
        })
        .catch((error) => {
            console.log(error);
            // window.location.replace("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code dashboard001');
        });
    }

    return(
    <>  
        <div>
        {dateString}
        </div>
        <div className='dashboard__filters'>
            <Button
            className='button-custom-shadow' 
            color="secondary_light" 
            size="small" 
            variant="contained" 
            onClick={filterActiveFn}
            >
            {!filterActive ? 'Hide Trial Expired' : 'Show all'}
            </Button>
        </div>

        <div className='dashboard__t-headings'>

            <div className='dashboard__h-ids'>
                Id
            </div>

            <div className='dashboard__h-emails'>
                Emails
            </div>

            <div className='dashboard__h-status'>
                Plan
            </div>

            <div className='dashboard__h-date'>
                Last Visit
            </div>

            <div className='dashboard__h-calc'>
                Начисления
            </div>

            <div className='dashboard__h-downloads'>
                Скачивания
            </div>

            <div className='dashboard__h-open-links'>
                Unique Views
            </div>

            <div className='dashboard__h-open-items'>
                Opens V
            </div>

            <div className='dashboard__h-open-emails'>
                Email opens
            </div>

            <div className='dashboard__h-searches'>
                Searches
            </div>

            <div className='dashboard__h-credits'>
                Credit-test
            </div>

        </div>

        {users?.map((item,id)=>{
                return (
                    <div className={'dashboard__item' + (((item.status === '5' || item.status == undefined)  && filterActive) ? ' dashboard__item--expired' : '')} key={id}>

                        <div className='dashboard__ids column-value'>
                            {item.id}
                        </div>

                        <div className='dashboard__emails column-value'>
                            <p>{item.email}</p>
                            <p>{item.ip}</p>
                        </div>

                        <div className='dashboard__status column-value'>
                                {item.status === '2' ?
                                
                                    <span className='status__label status__label--green'>
                                        1 Year Access
                                    </span>
                                :
                                item.status === '4' ?
                                    <span className='status__label status__label--yellow'>
                                        Free Trial
                                    </span>
                                :
                                item.status === '5' ?
                                    <span className='status__label status__label--red'>
                                        Expired Trial
                                    </span>
                                :
                                item.status === '6' ?
                                    <span className='status__label status__label--green'>
                                        3 Months Access
                                    </span> 
                                :
                                item.status === '7' ?
                                    <span className='status__label status__label--green'>
                                        6 Months Access
                                    </span> 
                                :
                                item.status === '3' ?
                                <span className='status__label status__label--green'>
                                    Access + Additional Credit
                                </span> 
                                :
                                item.status === '10' ?
                                <span className='status__label status__label--green'>
                                    1 Month Access
                                </span> 
                                :
                                item.status === '1' ?
                                <span className='status__label status__label--violet'>
                                ProPlan Expired
                                </span> 
                                :
                                <span className='status__label status__label--red'>
                                Deleted?
                                </span> 
                                }
                            <span className='status__dates'>

                                <span>
                                Rg -- {item['reg_date']?.toString().slice(0,10)}
                                </span>
                                <span>
                                St -- {item.paid_date === undefined ? 'Not paid' : item?.paid_date?.slice(0, 10)}
                                </span> {/*  {(item?.paid_date-).slice(0, 10)} */}
                                
                                <span>
                                En -- {endDateCalc(item.paid_date,item.status,item.paid_plan).toString().slice(0, 10)}
                                </span>
                        
                            </span>
                            {/* <span>
                                {isNaN(item.paid_date) ?
                                'Not paid' :
                                resultPoint(item.id,item.paid_date,item.status)
                                }
                                </span> */}
                        </div>

                        <div className='dashboard__date column-value'>
                            <span>
                                {item['last_action']?.toString().slice(0,10)}
                            </span>
                            <span>
                                {item['last_action']?.toString().slice(10)}
                            </span>
                        </div>

                        <div className='dashboard__calc column-value'>
                            {/* {item['reg_date']?.toString().slice(0,10)} */}
                            <div>
                                <span>
                                    Начислено:
                                </span>
                                <span>
                                    {' ' + item.downloads_total}
                                </span>
                            </div>
                            <div>
                                <span>
                                    Должны:
                                </span>
                                <span>
                                    {item?.status === '4' ? '10' : downloadDebt(item)}
                                </span>
                            </div>
                        </div>

                        <div className='dashboard__downloads column-value'>
                            <div>
                                <span>
                                    {item.downloads}{' / '}{(item.status === '2' || item.status === '6' || item.status === '7' || item.status === '10') ? '500' : '10'}
                                </span>
                                <p>
                                <span className='downloads__h-total-used'>
                                    Скачано:
                                </span>
                                <span className='downloads__total-used'>
                                    {' '}{item.download_ids.split(',').length-1 == '0' ? item.download_ids.split(',').length-1 : item.download_ids.split(',').length-1 + 1}
                                </span>
                                </p>
                                <IconButton 
                                color="primary" 
                                aria-label="add an alarm" 
                                className="update-credits"
                                onClick={()=>updateCredits(item.id)}
                                >
                                    <CachedIcon />
                                </IconButton>

                            </div>
                        </div>

                        <div className='dashboard__open-links column-value'>
                            <div>
                                <span>
                                {item['link_open']}
                                {' / '}
                                {viewsMaxFunc(item['status'])}
                                </span>

                                <p>
                                    <span className='open-links__h-total-used'>
                                        Просмотры:
                                    </span>
                                    <span className='open-links__total-used'>
                                        {(item['uniq_link_open'].split(',').length)-1}
                                    </span>
                                </p>

                            </div>
                        </div> 

                        <div className='dashboard__open-items column-value'>
                            {item['company_open']}
                        </div> 

                        <div className='dashboard__open-emails column-value'>
                            {item['email_open']}
                        </div> 

                        <div className='dashboard__searches column-value'>
                            {item['searches']}
                        </div> 

                        <div className='dashboard__credits column-value'>
                            {item['credits']}
                            <p>{item['month_update']}</p>
                            <p>{item['date_plan']}</p>
                        </div> 
                        
                        <div className='dashboard__icon-top'>
                            {item['month_update'] !== '0' ? <ExitToAppIcon /> : null}
                        </div>
                        {item.status === '4' ?

                        <div className='dashboard__icon-bottom'>
                            {<RemoveCircleIcon onClick={()=>openBlocker(item.id)}/>}

                            <div id={'block-'+item.id} className='blocker-item blocker-item--hidden' onClick={()=>blockUser(item.id)}>
                                Block User
                            </div>

                        </div>

                        : null }

                        <div className={'dashboard__feedback' + (item.feedback_status === '0' ? ' dashboard__feedback--disabled' : '')}>
                            {item.feedback_status !== '0' ?

                            <div>
                                <div>
                                    <span>
                                        Rate contacts:
                                    </span>
                                    <span>
                                        {item.feedback?.['db_rate']}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Text:
                                    </span>
                                    <span>
                                        {item.feedback?.['text']}
                                    </span>
                                </div>
                                
                            </div>
                            : null}
                        </div>
                    </div>
                );

            })

        }
    </>);
}