import React, { useEffect, useState } from 'react';

// Components
// import Distributor from './Distributor';
import DistributorNew from './DistributorNew';
import NotFoundImage from './../assets/vinaty-not-found.png';
import Loader from './../hooks/Loader';

// Material
// import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import Collapse from '@mui/material/Collapse';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam,BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function Content(props) {

    const {
        requestParams,
        result,
        setResult,
        chosenItems,
        setChosenItems,
        userDownloadIds,
        setUserDownloadIds,
        userCounter,
        setUserCounter,
        getCookie,
        userFolders,
        setUserFolders,
        userPlan,
        userExpired,
        pageNumber,
        setLimitViews,
        userViewsIds,
        setUserViewsIds,
        clearFilters,
        currentFolder,
        setCurrentFolder,
        setResultIds,
        downloadExcel,
        SingleDownload,
        setContentMenuHide,
        changeBookmarkCounter,
        setBookmarksCounter
    } = props;

    // States
    const [contentLoader,setContentLoader] = useState(true);
    
    // State open email in item, prevent opening multiple
    const [emailOpen,setEmailOpen] = useState(false);

    // Status of toggle of helper in Type
    const [openTypeInfo,setOpenTypeInfo] = useState(false);

    var urlApi;

    // State of opened action of each Item
    const [expanded, setExpanded] = useState('');

    // Toggle span about personal emails
    const [openPersonalEmailInfo,setOpenPersonalEmailInfo] = useState(0);

    // State modal info about email statuses         
    const [emailStatusInfoState,setEmailStatusInfoState] = useState(false);

    // Status email info
    function openEmailStatusInfo(event) {
        event.stopPropagation();
        setEmailStatusInfoState(prevState => !prevState);
    }
    const handleClickAway = (event) => {
        event.stopPropagation();
        if (emailStatusInfoState) {
            setEmailStatusInfoState(false);
        }
    };

    // Open Single Action menu onClick in each Item 
    function expandActionItem(id) {
        if (expanded !== id) {
            setExpanded(id);
        } else {
            setExpanded('');
        }
    }

    // Close all Single action onClick outside of menu if at least one is opened
    function closeAllSingleActions(event) {

        // if (expanded !== false && expanded !== '') {

        //     var activeAction = '';
        //     activeAction = document.getElementById('single-action-' + expanded);

        //     if (!activeAction?.contains(event.target)) {
        //         setExpanded('');
        //     }
        // }
    }

    // Event listener of content
    useEffect(() => {
        document.addEventListener('click', closeAllSingleActions);

        // cleanup this component
        return () => {
            document.removeEventListener('click', closeAllSingleActions);
        };

      }, [expanded]);
        

    //\\//\\// Request distributors from server with params \\//\\//\\

    function requestItems() {

        setContentMenuHide(true);
        
        urlApi = process.env.REACT_APP_URL_API + 'distributors';

        const queryDistributors = {
            "data": "distributors",
            "request_params": requestParams,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryDistributors)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data.token_code !== process.env.REACT_APP_CODE) {
                window.location.replace("https://vinaty.com/login/");
                // alert('ERROR Content request. Wrong token code. c001');
            } else {
                setResult(data);
            }
        })
        .catch((error) => {
            // console.log(error);
            console.log('error');
            // window.location.replace("https://vinaty.com/login/");
            // alert('ERROR Content request. Wrong token code. c001');
        });
    }

    // If requestParams changes call new fetch
    useEffect(()=>{
        requestItems();
        setContentLoader(true);
    },[requestParams]);
    
    useEffect(()=>{
        setContentLoader(true);
        if (result !== undefined) {
            setTimeout(()=>{
                setContentLoader(false);
            },300);
        }
        if (result !== undefined) {
            setCurrentFolder(result?.folders);
            setBookmarksCounter(result?.bookmark_counter);
        }
        var tempResultIds = [];
        for (let i = 0; i < result?.distributors.length; i++) {
            tempResultIds.push(result?.distributors[i].id);
        }
        setResultIds(tempResultIds);

    },[result]);

    // Handler of toggle of helper in Type
    function openTypeInfoHandler() {
        setOpenTypeInfo(prev => !prev);
        // if (openTypeInfo === item.id) {
        //     setOpenTypeInfo(0);
        // } else {
        //     setOpenTypeInfo(item.id);
        // }
    }
    // Test 
    function CloseTypeInfo(){
        setOpenTypeInfo(false);
    }

    return (
        <div>
            {contentLoader ?

                <div>

                    <Loader />

                </div>

            : result?.distributors?.length > 0 ?

                // ((userExpired && result.folders === '0') || !userExpired) ? 
                <>

                    <ClickAwayListener onClickAway={(event)=>handleClickAway(event)}>
                    <Fade 
                    in={emailStatusInfoState} 
                    className='modal-window-content'>
                        <div>
                            <div className='modal-window-content__close'>
                                <IconButton size="medium" aria-label="delete" onClick={openEmailStatusInfo}>
                                    <CloseIcon fontSize="medium"/>
                                </IconButton>
                            </div>

                            <div>
                                <h3>
                                Email Deliverability Status Explanation
                                </h3>

                                <div className='modal-window-content__content'>
                                    <h4>
                                    General status of company email addresses
                                    </h4>
                                    <ul className='modal-window-content__list'>

                                        <li>

                                            <span className='modal-window-content__status modal-window-content__status--green'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Email Verified 
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            This status means that every email address of this company has been confirmed and verified. Your message will be delivered to the addressee. Please note, however, the sub-statuses given below.
                                            </span>

                                        </li>

                                        <li>
                                            
                                            <span className='modal-window-content__status modal-window-content__status--yellow'>
                                                <HelpCenterIcon />
                                                <span>
                                                Emails with Notes
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            This company has several emails with different statuses. At least one email address can be used to send a message. For specific information, see the status of each email address within the contact.
                                            </span>

                                        </li>

                                        <li>
                                            
                                            <span className='modal-window-content__status modal-window-content__status--grey'>
                                                <HourglassTopIcon />
                                                <span>
                                                No Emails Found
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            This company currently does not have an email address on its profile. This may be because we removed an unresponsive address and are now looking for a replacement.
                                            </span>

                                        </li>

                                        <li>
                                            
                                            <span className='modal-window-content__status modal-window-content__status--ocean'>
                                                <ArrowOutwardIcon />
                                                <span>
                                                External Contacts
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            The company’s contacts are located on an external page, usually this is a personal email address, which, due to legislation, cannot be placed in the company’s profile. You can find the link inside the profile.
                                            </span>

                                        </li>

                                        <li>
                                            
                                            <span className='modal-window-content__status modal-window-content__status--red'>
                                                <DoNotDisturbIcon />
                                                <span>
                                                Email Inactive
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            This means that all email addresses are currently inactive or incorrect. We are working to replace these email addresses.
                                            </span>

                                        </li>


                                    </ul>
                                    <h4>
                                    Sub-statuses of company email addresses
                                    </h4>
                                    <ul className='modal-window-content__list'>

                                        <li>

                                            <span className='modal-window-content__status modal-window-content__status--green'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Verified
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            Email confirmed and working correctly
                                            </span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--green'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Verified | Role-based 
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            This email address is confirmed and working. ‘Role-based’ means that this email belongs to a specific work group (sales@, info@). Role-based emails has a higher percentage of send to spam than ‘Verified’ emails.</span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--yellow'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Verified | Catch-all
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                                ‘Catch-all’ means that, although the mail server is active, our checks do not show its precise status. Some emails sent to this server will bounce back.
                                            </span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--yellow'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Verified | Abuse
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                                The email address exists and is verified. Abuse means, these emails are of people known to click the abuse links in emails, hence abusers or complainers.
                                            </span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--yellow'>
                                                <MarkEmailReadIcon />
                                                <span>
                                                    Verified | Antispam
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                            We have confirmed that this email address exists and works, but an anti-spam system has been detected on the mail server, and your email may bounce back.
                                            </span>

                                        </li>
                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--grey'>
                                                <HourglassTopIcon />
                                                <span>
                                                Unknown
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                                We couldn’t validate this email. Typical cases include an offline mail server or an anti-spam email system. In most cases, unknown emails are invalid/bad email addresses.
                                            </span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--red'>
                                                <DoNotDisturbIcon />
                                                <span>
                                                Verified | Do not use
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                                Email owners are complainers, direct complainers, purchased addresses, domains that don't send mail, and known litigators.
                                            </span>

                                        </li>

                                        <li>
                                            <span className='modal-window-content__status modal-window-content__status--red'>
                                                <DoNotDisturbIcon />
                                                <span>
                                                Inactive email
                                                </span>
                                            </span>

                                            <span className='modal-window-content__desc'>
                                                This means that either the email is currently inactive or the email contained an error. We are working to replace these email addresses.
                                            </span>

                                        </li>

                                    </ul>

                                </div>
                                
                            </div> 
                        </div> 
                    </Fade>
                    </ClickAwayListener>

                    <div className='results__headings'>

                        <div className='results__container-headings'>

                            <div className='headings__checkbox'>
                            </div>


                            <div className='headings__name'>
                                Name
                            </div>
                            
                            <div className='headings__action-signs'>
                                Status
                                <Tooltip title={
                                <span className='info-span'>
                                The companies in the database are divided into three Tiers: A, B, C. C Tier: basic company information. B Tier: the company's data has been updated relatively recently and includes additional details (LinkedIn, number of employees, more useful links, and contacts). A Tier: the most complete and up-to-date information (relative to our database), if available for the company, includes details on the number of employees, financial indicators, legal information, key contacts, product quantities, and more.
                                </span>
                                } 
                                size='medium'>
                                <HelpIcon className='question-toggle' sx={{ fontSize: 20 }}/>
                                </Tooltip>
                            </div>

                            <div className='headings__region-country'>
                                Location
                            </div>
                            {/* <div className='headings__region'>
                                Region
                            </div> */}
                            {/* <div className='headings__country'> */}
                                {/* Country */}
                            {/* </div> */}

                            <div className='headings__type'>

                                <span className='type__text'>Type</span>

                                <HelpIcon className='question-toggle' sx={{ fontSize: 20 }} onClick={openTypeInfoHandler}/>
                        
                                <Collapse className='question-info' in={openTypeInfo} timeout="auto" unmountOnExit>
                                
                                    {/* <div className='question-info'>                                   */}
                                    <IconButton className='question-info__close' aria-label="delete" onClick={CloseTypeInfo}>
                                        <CloseIcon />
                                    </IconButton>
                                    {/* <div className='question-info__heading'> */}

                                        {/* <p className='question-info__intro'>
                                            This information is approximate. Most companies belong to several types at the same time.
                                        </p> */}
                                        
                                    {/* </div> */}

                                    <p className='question-info__heading'>
                                        <GiWineBottle size={24}/>
                                        Retailer
                                    </p>
                                    <p className='question-info__text'>
                                        Retailers are businesses that sell products directly to consumers. These can be restaurants, bars, wine stores. Companies can often be both a retailer and a wholesaler.
                                    </p>
                                    
                                    <p className='question-info__heading'>
                                        <BsBoxSeam size={20}/>
                                        Wholesaler
                                    </p>
                                    <p className='question-info__text'>
                                    Wine wholesalers buy a large quantity of wines and products directly from distributors. The wines are destined for retailers.
                                    </p>

                                    <p className='question-info__heading'>
                                        <PiTruck size={24}/>
                                        Distributor and Importers
                                    </p>
                                    <p className='question-info__text'>
                                    Wine distributors/importers have a business relationship with the wineries that they represent. Importers bring wines into a country from abroad. Distributors usually cover a certain territory or region.
                                    </p>
                                    <p className='question-info__heading'>
                                        <BsShopWindow size={24}/>
                                        Supermarket Chain
                                    </p>
                                    <p className='question-info__text'>
                                    Supermarkets are large retail chains, that share a brand and central management. At their core, they are retailers and have their own large distribution network.
                                    </p>

                                    {/* </div>   */}
                                </Collapse>

                            </div>

                            <div className='headings__products'>
                                Products
                            </div>

                            <div className='headings__size'>
                                Size
                            </div>

                            {/* <div className='headings__website'>
                                Website
                            </div> */}


                            {/* <div className='headings__updated'>
                                Updated
                            </div> */}

                            <div className='headings__emails'>

                                Contacts

                                <Tooltip title={
                                <span className='info-span'>
                                The number of contacts available for this company. From left to right: the number of verified emails, phone numbers, links to LinkedIn profiles (general and key employees), links (Facebook, LinkedIn, Instagram, Twitter, company website).
                                </span>
                                } 
                                size='medium'>
                                <HelpIcon className='question-toggle' sx={{ fontSize: 20 }}/>
                                </Tooltip>

                            </div>

                            <div className='headings__phones'>
                                {/* Phones */}
                            </div>

                            <div className='headings__linkedin'>
                                {/* Linkedin */}
                            </div> 

                            <div className='headings__social'>
                                {/* Links */}
                            </div>

                            {/* <div className='headings__action'>
                                Download
                            </div> */}

                            <div className='headings__opener'>
                                Open
                            </div>

                        </div>

                    </div>

                    {result?.distributors.map((item,id)=>{
                        return (
                            <DistributorNew 
                            item={item} 
                            id={id}
                            key={id}
                            downloaded={userDownloadIds?.includes(item?.id)}
                            viewed={userViewsIds?.includes(item?.id)}
                            chosenItems={chosenItems}
                            setChosenItems={setChosenItems}
                            setUserDownloadIds={setUserDownloadIds}
                            setUserViewsIds={setUserViewsIds}
                            userCounter={userCounter}
                            setUserCounter={setUserCounter}
                            userFolders={userFolders}
                            setUserFolders={setUserFolders}
                            getCookie={getCookie}
                            emailOpen={emailOpen}
                            setEmailOpen={setEmailOpen}
                            // openTypeInfo={openTypeInfo}
                            // setOpenTypeInfo={setOpenTypeInfo}
                            expanded={expanded}
                            expandActionItem={expandActionItem}
                            openPersonalEmailInfo={openPersonalEmailInfo}
                            setOpenPersonalEmailInfo={setOpenPersonalEmailInfo}
                            userPlan={userPlan}
                            openEmailStatusInfo={openEmailStatusInfo}
                            currentFolder={currentFolder}
                            pageNumber={pageNumber}
                            setLimitViews={setLimitViews}
                            // downloadExcel={downloadExcel}
                            SingleDownload={SingleDownload}
                            changeBookmarkCounter={changeBookmarkCounter}
                            />
                        );

                    })}

                </>
                // : 'test'
            : (userExpired && result.folders !== '0') ?

            <div className='results__expired'>

                    <p className='limit__heading'>Your Access Has Expired</p>
                    
                    <p className='limit__text'>You can still use your Download folder without restrictions. Please renew your subscription or contact us support@vinaty.com</p>
                    {/* <p className='expired__text'>You can use all the features with a paid access.</p> */}
                    
                    <div className='limit__buttons'>

                        <Button 
                        variant="contained" 
                        size="medium" 
                        // startIcon={<AccountBoxIcon />}
                        href="https://vinaty.com/account/"
                        target='_blank'
                        >
                        Get Full access
                        </Button>

                    </div>

                    <img src={NotFoundImage} width="400" height="229" />

                </div>
                
            : (!userPlan && result.folders !== '0' && result.folders !== '') ?

                <div className='results__expired'>

                    <p className='limit__heading'>Folders and Bookmarks are not available in Free Trial</p>
                    
                    <p className='limit__text'>During Free Trial period the Bookmarks and personal folders are not available. However, you can use the main distributor section. To get full access, please upgrade your account to full access.</p>
                    {/* <p className='expired__text'>You can use all the features with a paid access.</p> */}
                    
                    <div className='limit__buttons'>

                        <Button 
                        variant="contained" 
                        size="medium" 
                        // startIcon={<AccountBoxIcon />}
                        href="/">
                        Return to main distributors section
                        </Button>

                        <Button 
                        variant="contained" 
                        size="medium" 
                        // startIcon={<AccountBoxIcon />}
                        href="https://vinaty.com/account/"
                        target='_blank'
                        >
                        Paid access
                        </Button>

                    </div>

                    <img src={NotFoundImage} width="400" height="229" />

                </div>
            :
            <div className='results__nothing'>




                <p>Please try change search filters or change the folder</p>
                {(result?.result_counted < result?.counters?.total) ? 

                <Button 
                variant="contained" 
                size="large"
                className='nothing__clear'
                onClick={()=>clearFilters()}
                // startIcon={<AccountBoxIcon />}
                >
                Clear All Filters
                </Button>

                : null}

                <img src={NotFoundImage} width="600" height="339" />

            </div>
            }

        </div>
    );
}