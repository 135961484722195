import React, {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
// import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';

import CheckUser from '../hooks/CheckUser';

export default function UserInfo(props) {

    const {
        setSavedSearches,
        setUserFolders,
        userCounter,
        userDetails,
        setUserDetails,
        getCookie,
        deleteCookies,
    } = props;

    const [daysLeftState,setDaysLeftState] = useState('0');
    const [viewsLeft,setViewsLeft] = useState('0'); 
    const [dateCircleStart,setDateCircleStart] = useState('0');
    const [dateCircleEnd,setDateCircleEnd] = useState('0');
    const [cheat,setCheat] = useState(0);

    // const [showAd,setShowAd] = useState(false);

    useEffect(()=>{
        var daysPlan = 3;
        if (userDetails != 'undefined') {

            // User VIEWS
            // var totalViews = 0;
            var availableViews = 0;
            availableViews = userDetails['user_views'];
            // if (userDetails['user_views'] !== 'unknown' && userDetails['user_views'] !== 'undefined') {
            //     if (userDetails['test'] === 4) {
            //         totalViews = 25;
            //     } else {
            //         totalViews = 2000;
            //     }
            //     availableViews = userDetails['user_views'];
            // }
            setViewsLeft(availableViews);

            // User PLAN
            if (userDetails['user_payments']?.length > 0) {
                var lastPayment = userDetails['user_payments'][userDetails['user_payments'].length-1];
                var dateNow = Math.floor(Date.now()/1000);
                var datePaid = (new Date(lastPayment['arm_payment_date'])).getTime() / 1000;
                if (lastPayment['arm_plan_id'] === '4' && userDetails['user_plan'] !== 2) {
                    // setShowAd(true);
                    daysPlan = 1;
                } else if (lastPayment['arm_plan_id'] === '2') {
                    daysPlan = 365;
                } else if (lastPayment['arm_plan_id'] === '6') {
                    daysPlan = 90;
                } else if (lastPayment['arm_plan_id'] === '7') {
                    daysPlan = 180;
                } else if (lastPayment['arm_plan_id'] === '10') {
                    daysPlan = 30;
                }
                setDaysLeftState(Math.ceil((daysPlan) - (Math.abs(dateNow - datePaid)/(60 * 60 * 24))));

                // For Views logic
                var tempDate = '0';
                tempDate = lastPayment['arm_payment_date'];
                // setDateCircleStart(tempDate);
                setEndDate(tempDate);
                
            } else if (userDetails['user_payments']?.length === 0) {
                var dateNow = Math.floor(Date.now()/1000);
                var dateReg = (new Date(userDetails['user_registered'])).getTime() / 1000;
                setDaysLeftState(Math.ceil((1) - (Math.abs(dateNow - dateReg)/(60 * 60 * 24))));

                // For Views logic
                var tempDate = '0';
                tempDate = userDetails['user_registered'];
                // setDateCircleStart(tempDate);
                setEndDate(tempDate);
            }
        }
    },[userDetails]);

    function setEndDate(tempDate) {
        var day = tempDate.slice(8,10);
        var nextMonth = 1;
        if (new Date().getDate() < day) {
            nextMonth = 0;
        }
        var month = (new Date().getMonth()+1)%12 + nextMonth;

        var months_array = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
        var monthLetters = months_array[month-1];

        var year = new Date().getFullYear();
        if (month === 1) {
            year = year + 1;
        }
        // var resultDate = monthLetters + ' ' + day + ', ' + "'" + year.toString().slice(2,4);
        var resultDate = monthLetters + ' ' + day;

        setDateCircleEnd(resultDate);
    }

    return (
        <div className={'user-info'}>
            <CheckUser 
            setUserFolders={setUserFolders} 
            setSavedSearches={setSavedSearches} 
            setUserDetails={setUserDetails}
            getCookie={getCookie}
            setCheat={setCheat}
            />
            
            <div className='user-info__welcome'>
                {/* <p className='welcome__heading'>Welcome back,</p> */}
                <p className='welcome__name'>Hello, {userDetails['user_name']}</p>
                {/* <p className='welcome__email'>{userDetails['user_email']}</p> */}
            </div>

            <div className='user-info__container-credits'>

                <div className='user-info__expired'>
                    
                    <Button 
                    href='https://vinaty.com/account/' 
                    target='_blank' size={'small'} 
                    className='user-info__plan' 
                    variant="outlined" 
                    color='primary'
                    startIcon={<SettingsIcon />}
                    sx={{textTransform: 'none'}} 
                    >
                        {userDetails['user_plan'] === 0 ? 
                            'Testing mode' :
                        userDetails['user_plan'] === 1 ?
                            'Expired' :
                        userDetails['user_plan'] === 2 ?
                            'Full Access' :
                        userDetails['user_plan'] === 4 ?
                            'Free Trial' :
                        userDetails['user_plan'] === 5 ?
                            'Trial (expired)' :
                        // userDetails['user_plan'] === 6 ?
                        //     '3 Months Access' :
                        // userDetails['user_plan'] === 7 ?
                        //     '6 Months Access' :
                        ''
                        }
                        
                        {userDetails['woo_has_purchase'] && userDetails['woo_days_left'] > -1 ? 
                        (' (Exp. in ' + userDetails['woo_days_left'] + (userDetails['woo_days_left'] > 1 ? ' days)' : ' day)' ))
                        : (daysLeftState > -1 && userDetails['user_plan'] !== 1 && userDetails['user_plan'] !== 5) ?
                        ' (Exp. in ' + (daysLeftState) + ' ' + 'day' + (daysLeftState > 1 ? 's)': ')')
                        : daysLeftState < 1 && userDetails['user_plan'] === 1 && userDetails['user_plan'] === 5 ?
                        ''
                        : ''}

                    </Button>

                    {/* <p>
                    {(daysLeftState > -1 && userDetails['user_plan'] !== 1 && userDetails['user_plan'] !== 5) ?
                    'Exp. in ' + (daysLeftState) + ' ' + 'day' + (daysLeftState > 1 ? 's': '')
                    : 
                    'Your access has expired'
                    }
                    </p> */}

                </div>

                <div className='user-info__inner'>

                    <div className='user-info__credits'>
                        
                        <p className='user-info__counter'>
                            <span className='user-info__download'>
                            <DownloadIcon />
                            </span>
                            <span className='user-info__text-counter'>{userCounter}</span>
                        </p>

                        </div>

                    <div className='user-info__views'>
                        
                        <p className='user-info__counter'>
                            
                            <span className='user-info__view'>
                            <VisibilityIcon />
                            </span>
                            
                            <span id="views" className='user-info__text-counter'>{viewsLeft}</span>
                        </p>
                        {userDetails['user_plan'] !== 4 ? 
                        <Tooltip title={<span className='info-span'>Each month, you may download contacts for up to 300 companies and view the contacts of 700 companies. A view is counted only when a link (e.g., websites, emails) is opened and each company is counted only once. At the end of the month, these limits will reset to 300 downloads and 700 views. The number of downloads or views does not accumulate over time.</span>} 
                        size='medium'>
                        <IconButton size='small'>
                            <HelpOutlineIcon size='small'/>
                        </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={<span className='info-span'>During the trial period, you can download 10 company contacts and view the contacts of 50 companies (each company is counted only once). Re-downloading is free and does not count towards the limit.</span>}
                        size='medium'>
                        <IconButton size='small'>
                            <HelpOutlineIcon size='small'/>
                        </IconButton>
                        </Tooltip>
                        }
                    </div>
                    
                    {userDetails['user_plan'] === 2 && userDetails['test'] !== 10?
                        <div className='user-info__heading-credits'>
                            <span>
                            Upd. on {dateCircleEnd} 
                            </span>
                        </div>
                    : null}

                </div>

            </div>

            {/* <span className='user-info__refresh'>
                <a href="https://vinaty.com/auth/">
                    <RefreshIcon />
                    Refresh user info
                </a>
            </span> */}

            {cheat === 1 ?
            <div className='cheat-banner'>
                <h2>We noticed that this is a repeated registration.</h2>
                According to the website rules, only one account can be used for the trial period. Therefore, Trial Access in the new account has been suspended. If you find the Vinaty contact database useful, we encourage you to subscribe for full access in your account. If there has been an error, please contact us at support@vinaty.com
            </div>
            : null}

        </div>
    );
}