import React, {useState} from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Styles of modal window
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
  };


export default function ModalWindowDownload(props) {

    const {
        getCookie,
        downloadExcel,
        setUserDownloadIds,
        setUserCounter,
        OpenPreDownload,
        setOldDownload,
        setNewDownloads,
        userPlan,
        currentFolder,
        pageNumber,
        cantDownload,
        userCounter,
        newDownloads,
        chosenItems,
        setChosenItems,
        oldDownload,
        modalDownload,
        setModalDownload,
        UpdateChosenCounter,
        setSelectedAll,
        setAllChosen,
        notDownloadedSelected,
        UpdateModalDownload,
        downloadedSelected,
        setCantDownload,
        allowedItems
    } = props;

    var urlApi;

    // File format 
    const [fileformats,setFileformats] = useState({
        'fullcsv': false,
        'emailcsv': false,
        'fullxls': true,
        'emailxls': false,
    });

    // When checkbox changes Type of file formats
    function chosefiledownload(type) {
        setFileformats(prevState => ({ 
            ...prevState, // keep all others
            [type]: !prevState[type]
        }))
    }

    function ClosePreDownload() {
        setOldDownload(0);
        setNewDownloads(0);
        setModalDownload(false);
    }

    // Confirm download and request server to send file
    function MultipleDownloadServer() {

        urlApi = process.env.REACT_APP_URL_API + 'distributors';

        const queryMultiDownload = {
            "data": 'single-download',
            "token": getCookie(),
            "item": chosenItems,
        };
        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryMultiDownload)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (data.token_code !== process.env.REACT_APP_CODE) {
                window.location.replace("https://vinaty.com/login/");
                alert('ERROR Content request. Wrong token code. p004');
            } else {
                if (data['items'].length > 0) {
                    
                    var delayDownload = 0;
                    if (fileformats.fullcsv) {
                        download_csv(data['items'], 'WineDistributorDownload');
                        delayDownload = delayDownload + 1000;
                    }
                    if (fileformats.emailcsv) {
                        setTimeout(()=>{
                            download_emails_csv(data['emails'], 'EmailListDownload');
                        },delayDownload);
                        delayDownload = delayDownload + 1000;
                    }
                    if (fileformats.fullxls) {
                        setTimeout(()=>{
                            downloadExcel(data['items'],'profiles');
                        },delayDownload);
                        delayDownload = delayDownload + 1000;
                    }
                    if (fileformats.emailxls) {
                        setTimeout(()=>{
                            downloadExcel(data['emails'],'emails');
                        },delayDownload);
                    }
                        
                    setUserDownloadIds(data['user_downloads']);
                    setUserCounter(data['user_counter']);
                    setModalDownload(prev=>!prev);
                }
            }
        })
        .catch((error) => {
            // window.location.replace("https://vinaty.com/login/");
        });

    }

    // Download server answer
    function download_csv(data, sensor) {
        let refactoredHeaders = [];

        const formateddata = JSON.parse(
            JSON.stringify(data).replaceAll('&quot;', '').replaceAll("&#039;", "'").replaceAll("&amp;","&")
        );

        Object.keys(data[0]).forEach((header)=>{
            if (header === 'tel') {
                header = 'Telephone'
            } else if (header === 'prod_country') {
                header = 'Imported_Wines'
            } else if (header === 'id') {
                header = 'code'
            }
            refactoredHeaders.push(header.toUpperCase()); 
        });
        
        let csvHeader = refactoredHeaders.join(';') + '\n'; // header row
        // let csvBody = data.map(row => Object.values(row).join(';')).join('\n');
        let quote = '"';
        let csvBody = formateddata.map(row => quote + Object.values(row).join('";"') + quote).join('\n');
        
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvHeader + csvBody);
        hiddenElement.target = '_blank';
        hiddenElement.download = sensor + '.csv';
        hiddenElement.click();
    }

    // Download server answer
    function download_emails_csv(data, sensor) {
        if (data.length > 0) {
            let refactoredHeaders = [];
            Object.keys(data[0]).forEach((header)=>{
                if (header === 'id' || header === 'ID') {
                    header = 'code'
                }
                refactoredHeaders.push(header.toUpperCase()); 
            });
            
            let csvHeader = refactoredHeaders.join(';') + '\n'; // header row
            // let csvBody = data.map(row => Object.values(row).join(';')).join('\n');
            let quote = '"';
            let csvBody = data.map(row => quote + Object.values(row).join('";"') + quote).join('\n');
            
            var hiddenElement2 = document.createElement('a');
            hiddenElement2.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvHeader + csvBody);
            hiddenElement2.target = '_blank';
            hiddenElement2.download = sensor + '.csv';
            hiddenElement2.click();
        }
    }

    // Reduce number of chosen items
    function ReduceChosenItems(ins) {
        var tempChosen;
        if (ins === 'new') {
            setChosenItems(notDownloadedSelected);
            setNewDownloads(notDownloadedSelected.length);
            UpdateChosenCounter();
            setSelectedAll(false);
            setAllChosen(false);
            setOldDownload(0);
        } else if (ins === 'first') {
            tempChosen = chosenItems.slice(0, userCounter);
            UpdateModalDownload(tempChosen);
        } else if (ins === 'old') {
            UpdateModalDownload(downloadedSelected);
        } else if (ins === 'visible') {
            UpdateModalDownload(allowedItems);
            setCantDownload(false);
        }
    }

    return (
        <Modal
        open={modalDownload}
        onClose={OpenPreDownload}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='modal-summary'
        >
        <Box sx={style} className='modal-summary__modal'>

            <div className='modal-summary__heading'>

                <div className='modal-summary__text-icon'>
                <FileDownloadIcon />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Download Summary
                </Typography>
                </div>
                
                <IconButton className='modal-summary__close' aria-label="delete" onClick={ClosePreDownload}>
                    <CloseIcon />
                </IconButton>

            </div>

            <Typography className='modal-summary__text' id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {userCounter >= chosenItems.length ?  */}
                {(!userPlan && currentFolder !== "0" && pageNumber > 1) || cantDownload ?
                    <span className='modal-summary__notify-not-download'>
                        <span>You cannot download hidden contacts during the trial period. Please select only those companies that are open for viewing. Or purchase full access to download any contacts from the distributor database.</span>
                        <span className='modal-summary__buttons'>
                        {userCounter > 0 ?
                            <Button 
                            onClick={()=>ReduceChosenItems('visible')}
                            variant="contained"
                            color="primary_grey_light"
                            >
                                Select Visible
                            </Button>
                        : null}
                        </span>
                    </span>
                : userCounter >= Math.abs(newDownloads) ?
                <>
                    <span>You are going to download {chosenItems.length} distributor{chosenItems.length > 1 ? 's' : ''}. {' '}
                    {Math.abs(newDownloads)} credit{Math.abs(newDownloads) > 1 ? 's' : ''} will be deducted from your balance.{' Previously downloaded distributors (chosen '}{oldDownload}{') are not counted, it will be downloaded for free.'}</span>
                    <span>Your current balance: {userCounter} credit{userCounter > 1 ? 's' : ''}</span>
                    <span>Balance after download: {userCounter-newDownloads} credit{userCounter > 1 ? 's' : ''}</span>
                    
                    <span className='modal-summary__format-h'>Choose the file formats to download</span>
                    <span className='modal-summary__checkboxes'> 


                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={fileformats['fullxls']}
                            onChange={() => chosefiledownload('fullxls')}
                            name="check_full_xls"
                            color="primary_grey_light"
                            />  
                        }                                
                        label="Company Profile Excel"
                        />  
                        
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={fileformats['fullcsv']}
                            onChange={() => chosefiledownload('fullcsv')}
                            name="check_full_csv"
                            color="primary_grey_light"
                            /> 
                        }
                        label="Company Profile CSV"
                        />     
                                
                        <FormControlLabel
                        control={  
                            <Checkbox
                            checked={fileformats['emailxls']}
                            onChange={() => chosefiledownload('emailxls')}
                            name="check_email_xls"
                            color="primary_grey_light"
                            />   
                        }                         
                        label="Emails Excel"
                        />

                        <FormControlLabel
                        control={                           
                            <Checkbox
                            checked={fileformats['emailcsv']}
                            onChange={() => chosefiledownload('emailcsv')}
                            name="check_email_csv"
                            color="primary_grey_light"
                            />
                        }
                        label="Emails CSV"
                        />    


                    </span>
                    
                    <span className='modal-summary__buttons'>

                        {/* <Button 
                        variant="outlined"
                        onClick={OpenPreDownload}
                        color="primary_grey_light"
                        >
                            Cancel
                        </Button> */}

                        {/* {userCounter > 0 ? */}
                        <Button 
                        disabled={userCounter < 1 || notDownloadedSelected?.length === 0 || notDownloadedSelected?.length === chosenItems?.length}
                        onClick={()=>ReduceChosenItems('new')}
                        variant="outlined"
                        color="primary_grey_light"
                        >
                            Only new
                        </Button>
                        {/* : null } */}
                        
                        <Button
                        disabled={downloadedSelected?.length === chosenItems?.length || downloadedSelected < 1}
                        onClick={()=>ReduceChosenItems('old')}
                        variant="outlined"
                        color="primary_grey_light"
                        >
                            Only downloaded
                        </Button>

                        <Button 
                        onClick={MultipleDownloadServer}
                        variant="contained"
                        color="primary_grey_light"
                        >
                            Download
                        </Button>

                    </span>

                </>
                :
                <>
                    <span>
                        Unfortunately, not enough credits on your balance. 
                    </span>
                    
                    <span>
                        Your total balance is {userCounter} credit{userCounter > 1 ? 's' : ''} and you have chosen {chosenItems.length} distributor{chosenItems.length > 1 ? 's' : ''}
                        {' '} of which {Math.abs(newDownloads)} are new. This amount need to be deducted from your balance.
                    </span>
                    <span>
                        Please choose fewer distributors, add credits to your balance or refine company selection:
                    </span>


                    {/* <span className='modal-summary__settings'>
                        Refine company selection with one click:
                    </span> */}

                    <span className='modal-summary__buttons'>

                        {/* <Button 
                            variant="outlined"
                            onClick={OpenPreDownload}
                            >
                            Cancel
                        </Button> */}
                        {userCounter < 1 ?
                        <Button 
                            href="https://vinaty.com/account/" target="_blank"
                            variant="outlined">
                            Account
                        </Button>
                        : null 
                        }

                        <Button 
                        disabled={userCounter < 1 || notDownloadedSelected?.length === 0 || notDownloadedSelected?.length === chosenItems?.length}
                        onClick={()=>ReduceChosenItems('new')}
                        variant="contained"
                        color="primary_grey_light"
                        >
                            Only new
                        </Button>
                        
                        <Button
                        disabled={downloadedSelected < 1}
                        onClick={()=>ReduceChosenItems('old')}
                        variant="contained"
                        color="primary_grey_light"
                        >
                            Only downloaded
                        </Button>

                        {userCounter > 0 ?
                            <Button 
                            onClick={()=>ReduceChosenItems('first')}
                            variant="contained"
                            color="primary_grey_light"
                            >
                                Select first {userCounter}
                            </Button>
                        : null}

                    </span>

                </>
                }
            </Typography>
        </Box>
        </Modal>
    );
}