import React, { useState, useEffect } from 'react';
import { BsFillTelephoneFill } from "react-icons/bs";
import ReactCountryFlag from "react-country-flag";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Fade from '@mui/material/Fade';

export default function PhoneData(props) {

    const { tel, convertDate, itemid, hide } = props;

    const [phoneDataState,setPhoneDataState] = useState({
        'phone': 'unknown',
        'isvalid': 'unknown',
        'line': 'unknown',
        'carrier': '',
        'UTC': 'unknown',
        'country_code': 'unknown',
        'country_name': 'unknown',
        'intern_format': 'unknown',
        'local_format': 'unknown',
        'isintern': 'unknown',
        'validation_date': '',
    });
    const [openedPhoneDetails,setOpenedPhoneDetails] = useState(false);
    const [loadingBar,setLoadingBar] = useState(true);
    // var loading = true;

    useEffect(()=>{

        // If we in Trial mode
        if (tel == '+123 456 789') {

        // If Active plan
        } else {

            parsePhone(tel);

        }
    },[tel]);

    // Open bottom side of phone details
    function openPhone() {
        setOpenedPhoneDetails(prev => !prev);
    }

    // Get telephone from database with validation info
    function parsePhone(tel) {

            var urlApi = process.env.REACT_APP_URL_API + 'folders';
            // loading = true;
            const queryParseTel = {
                "data": "parse_phone",
                "tel_id": tel,
            };

            fetch(urlApi, {
                method: 'POST',
                headers: {
                    Authorization: process.env.REACT_APP_TOKEN_API,
                    },
                cache: 'no-store',
                body: JSON.stringify(queryParseTel)
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (data['length'] === 1) {
                    setPhoneDataState(data.tel_data);
                    
                }
                setTimeout(()=>{
                    setLoadingBar(false);
                },300);
            })
            .catch((error) => {
                // alert('Can not parse telephone number. Error code d011');
                console.log(error);
            });
    }

    function copyToClipboardPhone(el) {
        navigator.clipboard.writeText(el);
        var successSpan = document.getElementById('success-copied-phone-'+itemid);
        successSpan.classList.add('phone-save-span--blink');
        setTimeout(()=>{
            successSpan.classList.remove('phone-save-span--blink');
        },2500);
    }

    return (

                                                            
        <div className='btm-tel__inner btm-tel__inner--data'>

            {/* {phoneDataState.isvalid === "Valid" && !loadingBar ? */}
            <Fade unmountOnExit={true} in={phoneDataState.isvalid === "Valid" && !loadingBar} timeout={{ enter: 400, exit: 400 }}>
            <div>
            <div className='btm-tel__top'>

                <span className='btm-tel__icon'>
                    <BsFillTelephoneFill />
                </span>

                <span className='btm-tel__phone'>

                    {phoneDataState.intern_format}

                    <IconButton className='save-phone-button' onClick={()=>copyToClipboardPhone(phoneDataState.intern_format)}  color="primary" aria-label="add an alarm">
                        <ContentCopyIcon />
                    </IconButton>

                </span>

                <Divider className='divider-phone' sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <span id={'success-copied-phone-' + itemid} className='phone-save-span'>
                    Saved to clipboard
                </span>

                <span className='btm-tel__status'>
                    <CheckCircleIcon />{phoneDataState.isvalid === 'Valid' ? 'Verified' : phoneDataState.isvalid === 'unknown' ? 'Unknown' : 'Invalid' }
                </span>

                <span className='btm-tel__open'>
                    <Button 
                    className={'btm-tel__button' + (openedPhoneDetails ? ' btm-tel__button--opened' : '')} 
                    color="primary_grey_light" 
                    size="small" 
                    onClick={openPhone}
                    variant="contained" 
                    endIcon={<ArrowForwardIosIcon />}
                    >
                        Details
                    </Button>
                </span>

            </div>

            <Collapse in={openedPhoneDetails} timeout="auto" unmountOnExit>

                <div className='btm-tel__bottom'>

                    <div className='btm-tel__bottom-left'>

                        <div className='btm-tel__row btm-tel__country'>

                            <span>
                                <ReactCountryFlag
                                countryCode={phoneDataState.country_code}
                                svg
                                style={{
                                    width: '1.3em',
                                    height: '1.3em',
                                }}
                                />
                            </span>

                            <span>
                                {phoneDataState.country_name}
                            </span>

                            <span>
                            UTC: {phoneDataState.UTC}
                            </span>

                        </div>

                        <div className='btm-tel__row'>
                            Type: 
                            {phoneDataState.line === 'fixed_line' ? 
                            ' Fixed-line' 
                            : phoneDataState.line === 'toll_free' ? 
                            ' Toll Free'
                            : phoneDataState.line === 'voip' ?
                            ' VoIP'
                            : ' Mobile'}
                        </div>                        
                        <div className='btm-tel__row btm-tel__row--intern-call'>
                            International calls: {phoneDataState.isintern === 'yes' ? 
                            <span className='btm-tel__intern-true'><CheckCircleIcon /> Yes</span> 
                            : <span className='btm-tel__intern-false'><CancelIcon /> No</span>
                            }
                        </div>

                    </div>

                    <div className='btm-tel__bottom-right'>

                        <div className='btm-tel__row'>
                            Local format: {phoneDataState.local_format}
                        </div>
                        <div className='btm-tel__row'>
                            Operator: {phoneDataState.carrier !== '' ? phoneDataState.carrier : 'Unknown'}
                        </div>                       
                        <div className='btm-tel__row btm-tel__row--date-validate'>
                            <span>Validated: </span>
                            <span className='btm-tel__date'>{convertDate(phoneDataState.validation_date)}</span>
                        </div>
                    </div>
                </div>
            </Collapse>
            </div>
            </Fade>
            {/* :null } */}

            {/* {loadingBar ?  */}
                <Fade unmountOnExit={true} in={loadingBar && !hide} timeout={{ enter: 400, exit: 200 }}>
                <span className='btm-tel__empty btm-tel__empty--progress'>
                <BsFillTelephoneFill />            
                <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                    <LinearProgress color="primary" />
                </Stack>
                </span>   
                </Fade> 
            {/* : null} */}

            {/* {hide ?  */}
            <Fade unmountOnExit={true} in={hide} timeout={{ enter: 300, exit: 300 }}>
                <span className='btm-tel__empty'>
                <BsFillTelephoneFill />
               This phone number is not available during the trial
                </span>
            </Fade>  
            {/* : null} */}

            {/* {phoneDataState.isvalid === 'Invalid' ?  */}
            <Fade unmountOnExit={true} in={!loadingBar && phoneDataState.isvalid !== 'Valid'} timeout={{ enter: 400, exit: 300 }}>
                <span className='btm-tel__empty'>
                <BsFillTelephoneFill />
                <span className='invalid-phone'>
                    {phoneDataState.phone == 'unknown' ? 'We currently do not have a phone number for this contact.'
                    : 'The phone number is invalid. We will fix it soon.'}
                    </span>
                </span>
            </Fade>
            {/* : null} */}
           
        </div>
    );
}