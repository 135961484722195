import React, { useState, useEffect } from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';

export default function Search(props) {

    const {textSearchParams, setTextSearchParams, setTextSearch,textSearch} = props;

    const [searchValue,setSearchValue] = useState('');

    const [searchSettingsExpanded,setSearchSettingsExpanded] = useState(false);

    // State to detect if search too short
    const [searchShort,setSearchShort] = useState(false);

    // Open setting in search
    const handleClickExpandSettings = (event) => {
        event.stopPropagation()
        setSearchSettingsExpanded(!searchSettingsExpanded);
    }
    
    const handleChange = (event) => {
        setTextSearchParams(prevState => ({ 
        ...prevState, // keep all other key-value pairs
        [event.target.name]: event.target.checked,
        }))
    }

    // Renew text input field when Save loaded
    useEffect(()=>{
        setSearchValue(textSearch.query);
    },[textSearch]);

    useEffect(()=>{
        if (searchShort) {
            setSearchShort(false);
        }
    },[searchValue]);
    
    function sendSearchRequest() {
        if (searchValue.length > 3) {
            setTextSearch({
                'query': searchValue.replace("'", " ").replace("`", " ").replace("&", " ").replace("(", "").replace(")", ""),
                'timestamp': Date.now(),
            });
            setSearchShort(false);
        } else if (searchValue.length === 0) {
            setSearchShort(false);
            setTextSearch({
                'query': '',
                'timestamp': '',
            });
        } else {
            setSearchShort(true);
        }
    }
    
    // Clear filter input and update filters
    function clearSearch() {
        setSearchValue('');
        if (textSearch.query !== '') {
            setTextSearch({
                'query': '',
                'timestamp': '',
            });
        }
    }

    return (

        <div className='search'>

            <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
            >
                <SearchIcon 
                    className='search__icon-search'
                />

                <InputBase
                    id="search-input"
                    value = {searchValue}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search distributors"
                    inputProps={{ 'aria-label': 'Search' }}
                    className='search__input-base'
                    onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                    onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                        e.preventDefault();
                        sendSearchRequest();
                    }}}
                />

                {searchValue !== '' ?
                <IconButton 
                    className='search__icon-clear'
                    onClick={clearSearch}
                    type="button" 
                    aria-label="search"
                >
                    <ClearIcon />

                </IconButton>
                : null
                }

                <IconButton 
                    className='search__icon-settings'
                    onClick={handleClickExpandSettings} 
                    color="primary_icon_light"
                    size={'small'} 
                    // sx={{ fontSize: '0.8rem' }}
                    aria-label="Search Settings"
                >
                    <SettingsIcon />
                </IconButton>

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <IconButton 
                    className='search__icon-start'
                    onClick={sendSearchRequest} 
                    color="primary_icon_light"  
                    sx={{ p: '2px' }} 
                    aria-label="Search"
                    size={'small'}
                >
                    <ArrowCircleRightOutlinedIcon />
                </IconButton>

            </Paper>
            
            <>  

                <span className='search__info'>

                    {searchShort && searchValue.length < 4 ? <>Minimum 4 letters required</> : null}
                
                </span>
            
                {/* <Button onClick={handleClickExpandSettings} variant="text">Open settings</Button> */}

            </>
            <Collapse in={searchSettingsExpanded} className="search__container-settings" timeout="auto" unmountOnExit>
            <FormControl sx={{ m: 3 }} className="search__settings" component="fieldset" variant="standard">
                {/* <FormLabel component="legend">Search settings</FormLabel> */}
                <FormGroup>
                <FormControlLabel
                    control={
                    <Checkbox
                    id='company-checkbox'
                    checked={textSearchParams.c_name} 
                    onChange={handleChange} 
                    name="c_name" />
                    }
                    label="Company name"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                    id='address-checkbox'
                    checked={textSearchParams.address} 
                    onChange={handleChange} 
                    name="address"
                    />
                    }
                    label="Address"
                />
                <FormControlLabel
                    control={
                    <Checkbox 
                    id='website-checkbox'
                    checked={textSearchParams.website} 
                    onChange={handleChange} 
                    name="website" />
                    }
                    label="Website url"
                />
                </FormGroup>
            </FormControl>
            </Collapse>
        </div>
    );
}