import React, { useState } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import FeedbackThanks from './../assets/feedback_thanks.png';

import MoodBadTwoToneIcon from '@mui/icons-material/MoodBadTwoTone';
import SentimentDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentDissatisfiedTwoTone';
import SentimentNeutralTwoToneIcon from '@mui/icons-material/SentimentNeutralTwoTone';
import SentimentSatisfiedAltTwoToneIcon from '@mui/icons-material/SentimentSatisfiedAltTwoTone';
import SentimentVerySatisfiedTwoToneIcon from '@mui/icons-material/SentimentVerySatisfiedTwoTone';

export default function FeedbackForm(props) {

    const {
        setFeedback,
        feedbackForm,
        setFeedbackUserStatus,
        setFeedbackForm,
        getCookie,
        setUserCounter,
        userCounter
    } = props;

    const [answerState,setAnswerState] = useState({
        'database': 0,
        'site': 0,
        'price': 0,
        'text': ''
    });

    const [thanks,setThanks] = useState(0);

    const [trySend,setTrySend] = useState(false);

    function closeFeedbackForm() {

        setFeedbackForm(false);

        // if (!thanks && localStorage.getItem('feedback_status') !== 'false') {
        //     setFeedback(true);
        // }
        
        // setTimeout(()=>{
        //     setThanks(0);
        // },1000);
        // localStorage.setItem('feedback_status', 'false');

    }

    function sendFeedback () {

        setTrySend(true);
        if (
        answerState['database'] !== 0 
        // answerState['site'] !== 0  && 
        // answerState['price'] !== 0 &&
        // answerState['text'] != ''
        ) {

            var urlApi = process.env.REACT_APP_URL_API + 'folders';

            const queryFeedback = {
                "data": "send_feedback",
                "feedback": answerState,
                "token": getCookie()
            };
    
            fetch(urlApi, {
                method: 'POST',
                headers: {
                    Authorization: process.env.REACT_APP_TOKEN_API,
                    },
                cache: 'no-store',
                body: JSON.stringify(queryFeedback)
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setThanks(data);
                setFeedbackUserStatus(1);
                // localStorage.setItem('feedback_status', 'false');
                // setUserCounter(userCounter+15);
            })
            .catch((error) => {
                setThanks(2);
            });
        }

    }

    function answers(question,rate) {

        setAnswerState(prevState => ({
            ...prevState,
            [question]: rate
        }))
    }

    return (
        <div className={'feedbackform' + (feedbackForm ? ' feedbackform--active' : '')}>
            
            <div className='feedbackform__close'>
                    <IconButton size="small" aria-label="delete" onClick={closeFeedbackForm}>
                        <CloseIcon fontSize="medium"/>
                    </IconButton>
            </div>

            <div className='feedbackform__heading'>
                <div>
                    {/* Your feedback helps us to improve Vinaty for you */}
                    Was the database useful for you today?
                </div>
            </div>

           <div className='feedbackform__question'>

                {/* <span className='feedbackform__subheading'>
                
                </span> */}

                 <div className='feedbackform__picker'>
                    <IconButton 
                    className={"rating-1" + (answerState['database'] === 1 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('database',1)}
                    >
                        <MoodBadTwoToneIcon fontSize="large"/>
                    </IconButton>

                    <IconButton 
                     className={"rating-2" + (answerState['database'] === 2 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('database',2)}
                    >
                        <SentimentDissatisfiedTwoToneIcon fontSize="large"/>
                    </IconButton>

                    <IconButton 
                     className={"rating-3" + (answerState['database'] === 3 ? ' rating-active' : '')}  
                    size="large" 
                    onClick={()=>answers('database',3)}
                    >
                        <SentimentNeutralTwoToneIcon fontSize="large"/>
                    </IconButton>

                    <IconButton 
                     className={"rating-4" + (answerState['database'] === 4 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('database',4)}
                    >
                        <SentimentSatisfiedAltTwoToneIcon fontSize="large"/>
                    </IconButton>

                    <IconButton 
                     className={"rating-5" + (answerState['database'] === 5 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('database',5)}
                    >
                        <SentimentVerySatisfiedTwoToneIcon fontSize="large"/>
                    </IconButton>

                    {answerState['database'] === 0 && trySend ? 
                    
                    <div className='feedbackform__alarm'>
                        <ErrorIcon color='red'/>
                    </div>

                    : null}

                </div>
           

            </div> 

            {/* <div className='feedbackform__question'>
                <span className='feedbackform__subheading'>
                Was it convenient and easy to use the site?
                </span>
                <div className='feedbackform__picker'>
                    <IconButton 
                    className={"rating-1" + (answerState['site'] === 1 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('site',1)}
                    >
                        <MoodBadTwoToneIcon fontSize="large"/>
                    </IconButton>
                    <IconButton 
                    className={"rating-2" + (answerState['site'] === 2 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('site',2)}
                    >
                        <SentimentDissatisfiedTwoToneIcon fontSize="large"/>
                    </IconButton>
                    <IconButton 
                    className={"rating-3" + (answerState['site'] === 3 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('site',3)}
                    >
                        <SentimentNeutralTwoToneIcon fontSize="large"/>
                    </IconButton>
                    <IconButton 
                    className={"rating-4" + (answerState['site'] === 4 ? ' rating-active' : '')}  
                    size="large" 
                    onClick={()=>answers('site',4)}
                    >
                        <SentimentSatisfiedAltTwoToneIcon fontSize="large"/>
                    </IconButton>
                    <IconButton 
                    className={"rating-5" + (answerState['site'] === 5 ? ' rating-active' : '')} 
                    size="large" 
                    onClick={()=>answers('site',5)}
                    >
                        <SentimentVerySatisfiedTwoToneIcon fontSize="large"/>
                    </IconButton>
                    {answerState['site'] === 0 && trySend ? 
                    
                    <div className='feedbackform__alarm'>
                        <ErrorIcon color='red'/>
                    </div>

                    : null}
                </div>
            </div> */}

            {/* <div className='feedbackform__question'>
                <span className='feedbackform__subheading'>
                What is a fair price for access per year?
                </span>
                <div className='feedbackform__picker feedbackform__picker--price'>
                    <span
                    className={'feedbackform__price' + (answerState['price'] === 1 ? ' rating-active' : '')}
                    onClick={()=>answers('price',1)}>
                        150€
                    </span>
                    <span 
                    className={'feedbackform__price' + (answerState['price'] === 2 ? ' rating-active' : '')}
                    onClick={()=>answers('price',2)}
                    > 
                        250€
                    </span>
                    <span 
                    className={'feedbackform__price' + (answerState['price'] === 3 ? ' rating-active' : '')}
                    onClick={()=>answers('price',3)}
                    >
                        350€
                    </span>
                    <span 
                    className={'feedbackform__price' + (answerState['price'] === 4 ? ' rating-active' : '')}
                    onClick={()=>answers('price',4)}
                    >
                        500€
                    </span>
                    <span 
                    className={'feedbackform__price' + (answerState['price'] === 5 ? ' rating-active' : '')}
                    onClick={()=>answers('price',5)}
                    >
                        700€+
                    </span>
                    {answerState['price'] === 0 && trySend ? 
                    
                    <div className='feedbackform__alarm'>
                        <ErrorIcon color='red'/>
                    </div>

                    : null}
                </div>
            </div> */}

            <div className='feedbackform__question'>
                <span className='feedbackform__subheading'>
                Please write us a few words
                </span>
                
                <TextField
                    id="outlined-multiline-static"
                    name="opinion"
                    className='feedbackform__input'
                    inputProps={{style: {fontSize: 14}}} // font size of input text
                    InputLabelProps={{style: {fontSize: 14}}} // font size of input label
                    label="Your feedback"
                    multiline
                    rows={2}
                    onChange={(event)=>answers('text',event.target.value)}
                    value={answerState['text']}
                />
                {answerState['text'] === '' && trySend ? 
                    
                    <div className='feedbackform__alarm feedbackform__alarm--text'>
                        <ErrorIcon color='red'/>
                    </div>

                : null}

            </div>
            <Button 
                className='button-custom-shadow button-submit-feedback' 
                color="primary" 
                size="small" 
                variant="contained" 
                onClick={sendFeedback}
                >
                    Send Feedback
            </Button>

            <div className={'feedbackform__thanks' + (thanks === 1 || thanks === 2 ? ' feedbackform__thanks--active' : '')}>
                {thanks === 1 ?
                    <>
                    <div style={{marginBottom:"30px",fontWeight:"bold"}}>  
                        <p>Thank you!</p>
                        <p>We have received your feedback!</p> 
                    </div>
                        <img src={FeedbackThanks} width="100" height="104" />
                    {/* <div style={{marginTop:"30px"}}>  
                        <p>Your 15 downloads have been added.</p>
                        <p>Have a nice day!</p>
                    </div> */}
                    </>
                :
                <>
                <div style={{marginBottom:"30px",fontWeight:"bold"}}>  
                <p>Something went wrong... please check again later</p> 
                </div>
                <div style={{marginTop:"30px"}}>  
                    <p>Please send us a message to support@vinaty.com</p>
                </div>
                </>
                }
            </div>
        </div>
    );
}