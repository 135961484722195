import React, { useEffect } from 'react';


export default function CheckUser(props) {

    const {setSavedSearches,setUserFolders,setUserDetails,getCookie,setCheat} = props;

    // Variables etc
    var urlApi;
    var dectypted_token;
    //\\//\\// Decrypt token from Cookies \\//\\//\\
    function decryptToken() {
        
        urlApi = process.env.REACT_APP_URL_API + 'auth';
        const queryToken = {
            "data": 'token',
            "token": getCookie(),
        };
        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                // 'Content-Type': 'application/json',
                },
            cache: 'no-store',
            body: JSON.stringify(queryToken)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            dectypted_token = data;
            setUserDetails(dectypted_token['user_details']);
            setSavedSearches(dectypted_token['user_saves']);
            setUserFolders(dectypted_token['user_folders']);
            setCheat(dectypted_token['cheat']);
            // console.log(data);
        })
        .catch((error) => {
            console.log(error);
            // window.location.replace("https://vinaty.com/account/");
        });
    }
    useEffect(()=>{
        decryptToken();
    },[]);
}