import React, { useEffect, useState } from 'react';
import logoPng from'./assets/vinaty-logo.png';
import Cookies from 'universal-cookie';

import * as XLSX from 'xlsx';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Components
import Content from './components/Content';
import Heading from './components/Heading';
import Search from './components/Search';
import Filters from './components/Filters';
import Sidebar from './components/Sidebar/Sidebar';
import PaginationPagination from './components/Pagination/PaginationPagination';
import PaginationTotalResults from './components/Pagination/PaginationTotalResults';
// import FeedbackForm from './components/FeedbackForm';
import Dashboard from './Dashboard';
import UpdatesList from './components/UpdatesList';
import ContentMenu from './components/Pagination/ContentMenu';

// Materials
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Styles of Material Ui
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "rgb(197, 180, 196)",
            color: "rgb(245, 245, 245)"
          }
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {

    primary: {
      main: '#8d5289',
      light: '#a86aa4',
      dark: '#7d4979'
    },
    primary_light: {
      main: '#c093be',
      light: '#b785b4',
      dark: '#b785b4',
      contrastText: "#fff"
    },
    primary_grey_light: {
      main: 'rgb(170, 132, 168)',
      light: '#c6b5c6',
      dark: 'rgb(161, 111, 159)',
      contrastText: "#fff"
    },   
    primary_grey_light_light: {
      main: 'rgb(230, 218, 229)',
      light: 'rgb(237, 232, 237)',
      dark: 'rgb(204, 184, 204)',
      contrastText: "#fff"
    },
    primary_red_light: {
      main: 'rgb(177, 74, 95)',
      light: 'rgb(202, 100, 121)',
      dark: 'rgb(161, 59, 79)',
      contrastText: "#fff"
    },
    primary_icon_light: {
      main: '#a86aa4',
      light: '#e9cee8',
      dark: '#8d5289'
    },
    secondary: {
      main: '#b9b3cb',
      light: '#ced6ed',
      dark: '#8f9abd'
    },
    secondary_light: {
      dark: '#CEE2F5',
      light: '#fff',
      main: '#E4F0FB',
    },
    blue_button_link: {
      dark: 'rgb(228, 239, 249)',
      light: '#fff',
      // main: 'rgb(237, 244, 253)',
      main: 'rgb(243, 247, 250)',
    },
    insta_light: {
      dark: '#e9d6df',
      light: '#f1e3ea',
      main: '#f1e3ea',
    },
    x_light: {
      dark: '#e3e3e3',
      light: '#efefef',
      main: '#efefef',
    },
    linkedin_light: {
      dark: '#ee557b',
      light: '#ee557b',
      main: '#ee557b',
    },
    inactive: {
      main: '#d0cbd0',
      dark: '#d0cbd0',
      light: '#d0cbd0',
    },
    red: {
      main: 'rgb(198 18 18 / 45%)',
      dark: 'rgb(198 18 18 / 75%)',
      light: 'rgb(198 18 18 / 20%)',
    },
    red_dark: {
      main: 'rgb(198 18 18 / 58%)',
      dark: 'rgb(198 18 18 / 70%)',
      light: 'rgb(198 18 18 / 50%)',
    },
    green_dark: {
      main: 'rgb(98 156 29 / 58%)',
      dark: 'rgb(98 156 29 / 70%)',
      light: 'rgb(98 156 29 / 50%)',
    },
    green_menu: {
      main: 'rgb(115, 195, 115)',
      dark: 'rgb(103, 185, 103);',
      light: 'rgb(170, 238, 170)',
      contrastText: "#fff",
    },
    white: {
      main: '#f3f3f3',
      dark: '#dbdbdb',
      light: '#ffffff',
    },
    opener_locked: {
      main: 'rgb(120, 120, 120);',
      dark: 'rgb(73, 73, 73)',
      light: 'rgb(204, 204, 204)',
    }
  },
})

function App() {

  // var salesIqLocal = 'true';

  // limitViews
  const [limitViews,setLimitViews] = useState(false);
  
  // Copy of filter state to compare and avoid dublicateed request
  const [copyFilters,setCopyFilters] = useState({});

  // Show/Hide Feedback
  const [feedback,setFeedback] = useState(true);
  const [feedbackForm,setFeedbackForm] = useState(false);

  // Prevent load if no cookies, design only, not a real security
  const [closedScreen,setClosedScreen] = useState(true);
  const [wrongToken,setWrongToken] = useState(false);
  
  // Preloader 
  const [preLoader,setPreloader] = useState(true);

  // Folder stats: opend,edit etc
  const [newFolderSlide,setNewFolderSlide] = useState(false); // Is creation of folder opened?
  const [currentFolderEdit,setCurrentFolderEdit] = useState(0); // If edit folder save the id

  // State for PaginationSave
  const [openedSave,setOpenedSave] = useState(false);

  // State from Filters, selected countries displayed in Country Select
  const [selectedCountries,setSelectedCountries] = useState([]);

  // CHECKER COOKIES //
  const cookies = new Cookies();
  
  // Multiple choser of items in pagination
  const [allChosen,setAllChosen] = useState(false);

  // State to hide all the details for Trial plan 
  const [userPlan,setUserPlan] = useState(false);

  // Selected All distributors
  const [selectedAll,setSelectedAll] = useState(false);

  // State to show or disable Feedback form in heading
  // const [feedbackUserStatus,setFeedbackUserStatus] = useState(0);
  
  // Is it admin? 
  const [admin,setAdmin] = useState(false);
  const [defender,setDefender] = useState(false);

  // User inActive (expired)
  const [userExpired,setUserExpired] = useState(false);
  
  // Current Folder
  const [currentFolder,setCurrentFolder] = useState('');
  
  // User Downloads
  const [userDownloadIds,setUserDownloadIds] = useState([]);

  // User Views
  const [userViewsIds,setUserViewsIds] = useState([]);

  // Check if server production or local dev //
  var testServer = process.env.REACT_APP_SERVER;

  // Update list state > Filters tp UpdatesList
  const [updateArrState,setUpdateArrState] = useState([]);

  // NOTIFICATION FROM ADMIN 
  const [notificationState,setNotificiationState] = useState([]);

  // NOTIFICATION DISCOUNT
  const [discountState,setDiscountState] = useState([]);

  // Displayed Items IDS
  const [resultIds,setResultIds] = useState([]); 

  // Trigger State to Download single item from Multi
  const [singleDownloadState,setSingleDownloadState] = useState(0);

  const [chosenOnThisPage,setChosenOnThisPage] = useState([]);

  // Hide Content Menu while loading results
  const [contentMenuHide,setContentMenuHide] = useState(true);

  // Counter of user Bookmarks - 21.08.24
  const [bookmarksCounter,setBookmarksCounter] = useState(0);

  // Download summary open
  const [modalDownload,setModalDownload] = useState(false);

  // Dashboard manual enable
  const [adminDashboard,setAdminDashboard] = useState(false);

  function enableAdminDashboard () {
    setAdminDashboard(adminDashboard=>!adminDashboard);
  }

  // Disable Preloader
  useEffect(()=>{
    if (!preLoader) {
      document.querySelector('body').style.overflow = "auto";
    }
  },[preLoader]);

  //\\//\\// Check cookies in the user browser \\//\\//\\
  function getCookie() {
    var cookieCheck = cookies.get('app_vinaty_token');
    if (typeof cookieCheck === "undefined") {
      setClosedScreen(true);
        setWrongToken(true);
        window.location.replace("https://vinaty.com/auth/");
    } else {
      setClosedScreen(false);
      return cookieCheck;
    }
  }


  function deleteCookies() {
    if (testServer === 'local') {
      cookies.remove("app_vinaty_token", {path: "/", domain: "localhost"});
    } else if (testServer === 'production') {
      cookies.remove("app_vinaty_token", {path: "/", domain: ".vinaty.com"});
    }
  }

  useEffect(()=>{

    getCookie();

    // // Retrieve data from local storage
    // salesIqLocal = localStorage.getItem("salesIqLocal");
    // if (salesIqLocal == null || salesIqLocal === 'true') {
    //   setShowChat(true);
    // } else {
    //   setShowChat(false);
    // }
  },[]);

  // Vars
  var urlApi;
  var timeFilter = '0'; // initial time filter 0 - false, 1 - one week, 2, 3, 4

  // All user info
  const [userDetails,setUserDetails] = useState({});

  // Saved searches State
  const [savedSearches,setSavedSearches] = useState([]);

  // User Folders State 
  const [userFolders,setUserFolders] = useState([]);

  //\\// MENU STATES IN SIDEBAR //\\//
  const [savesOpened,setSavesOpened] = useState(false); // Is saves opened?
  const [foldersOpened,setFoldersOpened] = useState(true); // Is folders opened?
  
  // When userDetails updated - get user downloads as an array
  const [userCounter,setUserCounter] = useState('');
  
  useEffect(()=>{
    if (userDetails['downloads_ids'] !== undefined) {
      setUserDownloadIds(userDetails['downloads_ids'].split(","));
    }
    if (userDetails['views_ids'] !== undefined) {
      setUserViewsIds(userDetails['views_ids'].split(","));
    }
    // Disable preloader
    if (userDetails.user_id !== undefined) {
      setTimeout(()=>{
        setPreloader(false);
      },500);
    }
    if(userDetails['user_plan'] !== undefined && userDetails['user_plan'] !== 4) {
      setUserPlan(true);
    }
    
    if (userDetails['feedback_status'] === 0 && localStorage.getItem('feedback_status') !== 'false') {
      setFeedback(true);
      
    };
    
    setUserCounter(userDetails['user_credits']);

    // setFeedbackUserStatus(userDetails['feedback_status']);

    if ((
      userDetails.user_id === 342 || 
      userDetails.user_id === 42) && !defender) {
      setAdmin(true);
    }

    // Check if user expired
    // Если Free trial expired / Pro plan expired
    if (userDetails.user_plan === 1 || userDetails.user_plan === 5) {
      setUserExpired(true);
    } else {
      setUserExpired(false);
    }

  },[userDetails]);

  useEffect(()=>{
    if (userExpired === true) {
      setRequestParams(prevState => ({ 
        ...prevState, // keep all other key-value pairs
        'user_expired': userExpired // update the value of specific key
      }))
    }
  },[userExpired])

  // Answer of the server with distributors and info
  const [result,setResult] = useState();

  const [textSearch,setTextSearch] = useState({
    'query': '', // string
    'timestamp': '', // string
  });

  const [textSearchParams,setTextSearchParams] = useState({
    'c_name': true,
    'address': true,
    'website': true,
  }); // array

  // Pagination user choose how many item to show, max 100 on server limit
  const [perPage,setPerPage] = useState(25); // string number

  const [pageNumber,setPageNumber] = useState(1); // string number current start page

  // Filters object
  const [filters,setFilters] = useState({
    'region': [],
    'country': [],
    'province': [],
    'size': [],
    'products': [],
    'type': [],
    // 'only_importers': false,
    'only_emails': false,
    'prod_country': [],
    'prod_strict': false,
    'time_added': '0',
    'time_updated': '0',
    'folder': '',
  });
  
  // Folder and filters
  const [requestParams,setRequestParams] = useState({
    'filters': filters,
    'time_filter': timeFilter,
    'text_search': textSearch,
    'text_search_params': textSearchParams,
    'per_page': perPage,
    'page_number': pageNumber,
    'user_expired': userExpired,
  });

  // State chosen items Content - Distributor // PaginationAction
  const [chosenItems,setChosenItems] = useState([]); 

  // Store currencies
  const [currencies,setCurrencies] = useState('');

  // Update request state when new filters comes
  useEffect(()=>{

    if (JSON.stringify(filters) !== JSON.stringify(copyFilters)) {

      setRequestParams(prevState => ({ 
        ...prevState, // keep all other key-value pairs
        'filters': filters, // update the value of specific key
        'page_number': 1
      }))

      setCopyFilters(filters);

    }
    setChosenItems([]);
    setAllChosen(false);
    setSelectedAll(false);
    setPageNumber(1);
  },[filters]);

  // Update request state when text search applied
  useEffect(()=>{

    setRequestParams(prevState => ({ 
      ...prevState, // keep all other key-value pairs
      'text_search': textSearch // update the value of specific key
    }))

    setRequestParams(prevState => ({ 
      ...prevState, // keep all other key-value pairs
      'text_search_params': textSearchParams // update the value of specific key
    }))
    setPageNumber(1);
  },[textSearch]);

  // Update request state when number of pages changes
  useEffect(()=>{
    setRequestParams(prevState => ({ 
      ...prevState, // keep all other key-value pairs
      'page_number': pageNumber,
    }))
    // setChosenItems([]);
    UpdateChosenItems();
  },[pageNumber]);

  // Update request state when number of pages changes
  useEffect(()=>{
    setRequestParams(prevState => ({ 
      ...prevState, // keep all other key-value pairs
      'per_page': perPage, // update the value of specific key
    }))
    // UpdateChosenItems();
  },[perPage]);


  useEffect(()=>{
    UpdateChosenItems();
  },[chosenItems]);

  function UpdateChosenItems() {
    setChosenOnThisPage([]);
    setAllChosen(false);
    if ((chosenItems.length > 0) && chosenItems.length >= resultIds.length) {
      if (resultIds.every(value => chosenItems.includes(value))) {
        setAllChosen(true);
      }
    } else {
      setAllChosen(false);
    }
  }

  // Choose Folder in sidebar menu
  function chooseFolder(id_folder) {
    setFilters(prevState => ({ 
      ...prevState, // keep all others
      'folder': filters['folder'] === id_folder ? '' : id_folder // update the value of specific key
    }))

    // Disable others active menus
    if (savesOpened) {
      setSavesOpened(false);
    }
  }

  //\\//\\// SECTION SAVE CURRENT FILTER //\\//\\//

  // Name of the Save when user creates new Save
  const [saveName,setSaveName] = useState(''); // string text

  // State of Save button Open/Closed
  const [saved,setSaved] = useState(false); // boolean
  useEffect(()=>{
    setSaved(false);
  },[requestParams]);

  // Save current search
  function saveSearch () {

    setSaved(true);

    urlApi = process.env.REACT_APP_URL_API + 'folders';

    const querySave = {
        "data": "save",
        "request_params": JSON.stringify(requestParams),
        "save_name": saveName,
        "token": getCookie()
    };
    fetch(urlApi, {
        method: 'POST',
        headers: {
            Authorization: process.env.REACT_APP_TOKEN_API,
            },
        cache: 'no-store',
        body: JSON.stringify(querySave)
    })
    .then(response => {
        return response.json();
    })
    .then(data => {
        setSavedSearches(data);
    })
    .catch((error) => {
        window.location.replace("https://vinaty.com/login/");
        alert('Something went wrong during save of the content. Error code 003');
    });

  }

  // Delete current search
  function deleteSave(event) {
    var save_id = event.currentTarget.dataset.itemid;

    urlApi = process.env.REACT_APP_URL_API + 'folders';

    const querySave = {
        "data": "delete_save",
        "save_id": save_id,
        "token": getCookie()
    };
    fetch(urlApi, {
        method: 'POST',
        headers: {
            Authorization: process.env.REACT_APP_TOKEN_API,
            },
        cache: 'no-store',
        body: JSON.stringify(querySave)
    })
    .then(response => {
        return response.json();
    })
    .then(data => {
        setSavedSearches(data);
    })
    .catch((error) => {
        window.location.replace("https://vinaty.com/login/");
        alert('Something went wrong during save of the content. Error code 004');
    });
  }

  function openSaves(){

    setNewFolderSlide(false); // Close folders creation menu
    setCurrentFolderEdit(0); // Close folder edit menu
  
    setSavesOpened((current => !current));

    // Disable others active menus
    if (filters['folder'] === "0") {
      setFilters(prevState => ({ 
        ...prevState,
        'folder': ''
      }))
    }
  }

  // OnClick in Menu Folders 
  function openFolders() {

    setFoldersOpened((current => !current));
    if (filters['folder'] !== '') {
      setFilters(prevState => ({ 
        ...prevState,
        'folder': ''
      }))
    }
  }

  // Toggle Sidebar in responsive design
  function toggleSidebar() {
    var sidebar = document.getElementById('sidebar');
    var toggle = document.getElementById('toggle-sidebar');

    if (!sidebar.classList.contains('sidebar-active')) {
      sidebar.classList.add("sidebar-active");
      sidebar.style.left = '0';
      toggle.classList.remove("toggle-sidebar--active");
    } else {
      sidebar.classList.remove("sidebar-active");
      sidebar.style.left = '-500px';
      toggle.classList.add("toggle-sidebar--active");
    }
  }

  // Check all items 
  function checkAll(source) {

    var itemsAll = result.distributors;
    var tempArrayAllItems = [];
    for(let i = 0; i < itemsAll.length; i++){
      if (itemsAll[i]['updated_timestamp'] !== "2024-03-18 15:05:00") {
        tempArrayAllItems.push(itemsAll[i].id);
      }
    }  

    // If checkbox
    if (source === 'pagination') {

      // All chosen FALSE
      if (!allChosen) {  
            setChosenOnThisPage(tempArrayAllItems); // Only this page
            var clearAllFromThisPage = tempArrayAllItems.filter(item => !chosenItems.includes(item));
            setChosenItems(clearAllFromThisPage.concat(chosenItems)); // All pages

      // All chosen TRUE on this page
      } else {
        var tempSubstract = [];
        var tempitemsAllIds = itemsAll.map(a => a.id);
        tempSubstract = chosenItems.filter(item => !tempitemsAllIds.includes(item));
        setChosenItems(tempSubstract);
        setChosenOnThisPage([]);
        
      }
      setAllChosen((prevState) => !prevState); // THIS page

    // If check all comes from multibar
    } else if (source === 'multibar') {
      if (chosenItems.length > 0) {
        setChosenItems([]);
        setAllChosen(false);
        setSelectedAll(false);
      } else {
        // Seems that this is not used
        for(let i = 0; i < itemsAll.length; i++){
          if (itemsAll[i]['updated_timestamp'] !== "2024-03-18 15:05:00") {
            tempArrayAllItems.push(itemsAll[i].id);
          }
        }
        setChosenItems(tempArrayAllItems);
        setAllChosen(true);
      }
    } else if (source === 'select-all') {
      setSelectedAll(true);
      setChosenItems([]);
      setChosenItems(result.result_ids);
      setAllChosen(true);
      var tempThisPageIds = [];
      for(let i = 0; i < itemsAll.length; i++){
        if (itemsAll[i]['updated_timestamp'] !== "2024-03-18 15:05:00") {
          tempThisPageIds.push(itemsAll[i].id);
        }
      }
      setChosenOnThisPage(tempThisPageIds);
    } else if (source === 'deselect-all') {
      setSelectedAll(false);
      setChosenItems([]);
      setAllChosen(false);
      setChosenOnThisPage([]);
    }
    
  }
  
  // Function load bookmarks
  function openBookmarks() {
    if (filters['folder'] !== '-1') {
      // setBookmarksOpened((current => !current));
      setFilters(prevState => ({ 
        ...prevState,
        'folder': '-1'
      }))
    } else {
      setFilters(prevState => ({ 
        ...prevState,
        'folder': ''
      }))
    }
  }

  function clearFilters() {
    // Filters object
    setFilters({
      'region': [],
      'country': [],
      'province': [],
      'size': [],
      'products': [],
      'type': [],
      // 'only_importers': false,
      'only_emails': false,
      'prod_country': [],
      'prod_strict': false,
      'time_added': '0',
      'time_updated': '0',
      'folder': '',
    });
    
    // Folder and filters
    setTextSearch({
        'query': '', // string
        'timestamp': '', // string
      });
    setTextSearchParams({
        'c_name': true,
        'address': true,
        'website': true,
      });
    setSelectedCountries([]);
    setPageNumber(1);
  }

  function downloadExcel(dataIn,sensor) {

    // Преобразование JSON в лист Excel
    const ws = XLSX.utils.json_to_sheet(dataIn);

    // Создание новой книги и добавление листа
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Сохранение файла
    var FileName = 'WineDistributors_' + sensor + '.xlsx';
    XLSX.writeFile(wb, FileName);

  }

  // When in Item Click Download
  function SingleDownload(item_id) {
    setSingleDownloadState(item_id);
    setChosenItems([item_id])
  }

  useEffect(()=>{
    UpdateChosenItems();
    setTimeout(()=>{
      setContentMenuHide(false);
    },250);
  },[resultIds]);

  // Change manually bookmark counter
  function changeBookmarkCounter(value){
    var tempCounter = bookmarksCounter + value;
    setBookmarksCounter(tempCounter);
  }

  return (

    <ThemeProvider theme={theme}>
      {!closedScreen ? 
        admin && adminDashboard ? 
          <div className='dashboard'>
            <Dashboard 
              setAdmin={setAdmin}
              setDefender={setDefender}
              getCookie={getCookie}
              user={userDetails['user_id']}
            />
          </div>
      :
      <div className={'main' + (preLoader ? ' main--disabled' : '') + (notificationState.length > 0 ? ' main--notification' : '')}>

          {preLoader ? 

            <>
                    
              <div className='preloader'>
                
                <div className='preloader__logo'>

                  <img src={logoPng} alt="Vinaty Logo"/>

                </div>

                <Box className ='no-access__circle-loader' sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </div>
            </>

          : null}
          
          {notificationState.length > 0 ? 
            <div className='main__notification'>
              <div className='notification__heading'>
              {notificationState[0].heading}
              </div>
              <div className='notification__text'>
              {notificationState[0].text}
              </div>
            </div>
          : null}
          
          <Sidebar 
          toggleSidebar={toggleSidebar}
          chooseFolder={chooseFolder}
          userDownloadIds={userDownloadIds}
          openBookmarks={openBookmarks}
          openFolders={openFolders}
          foldersOpened={foldersOpened}
          userFolders={userFolders}
          setUserFolders={setUserFolders}
          getCookie={getCookie}
          filters={filters}
          setSavesOpened={setSavesOpened}
          newFolderSlide={newFolderSlide}
          setNewFolderSlide={setNewFolderSlide}
          currentFolderEdit={currentFolderEdit}
          setCurrentFolderEdit={setCurrentFolderEdit}
          savesOpened={savesOpened}
          savedSearches={savedSearches} 
          deleteSave={deleteSave} 
          setFilters={setFilters}
          setTextSearch={setTextSearch}
          openSaves={openSaves}
          setTextSearchParams={setTextSearchParams}
          enableAdminDashboard={enableAdminDashboard}
          admin={admin}
          bookmarksCounter={bookmarksCounter}
          />

          <div className='content'>
            
            <div className={'content__limit-note' + (limitViews ? ' content__limit-note--active' : '')}>
              <div className='limit-note__heading'>
                <span className='limit-note__h-text'>Viewing limit reached</span>
                <span className='limit-note__close'>
                  <IconButton size="large" aria-label="delete" onClick={()=>(setLimitViews(false))}>
                    <CloseIcon fontSize="large"/>
                  </IconButton>
                </span>
              </div>
              <span className='limit-note__text'>
              Your viewing limit of companies has been reached (2000 views per month). You can continue working with the database, but only previously viewed companies are accessible to you.
              Please, contact us at support@vinaty.com if you have any questions.
              </span>
            </div>

            <Heading 
              setUserFolders={setUserFolders} 
              setSavedSearches={setSavedSearches} 
              userCounter={userCounter} 
              userDetails={userDetails} 
              setUserDetails={setUserDetails}
              getCookie={getCookie}
              deleteCookies={deleteCookies}
              toggleSidebar={toggleSidebar}
              discountState={discountState}
              setDiscountState={setDiscountState}
              feedback={feedback}
              setFeedback={setFeedback}
              feedbackForm={feedbackForm}
              setFeedbackForm={setFeedbackForm}
              userPlan={userPlan}
            />

            <div className='header'>

              <div className='underheading'>

                <Search
                textSearchParams={textSearchParams}
                setTextSearchParams={setTextSearchParams}
                textSearch={textSearch}
                setTextSearch={setTextSearch}
                />

                <UpdatesList 
                updateArrState={updateArrState}
                />

              </div>

              <Filters 
                setFilters={setFilters}
                filters={filters}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                setUpdateArrState={setUpdateArrState}
                setNotificiationState={setNotificiationState}
                setDiscountState={setDiscountState}
                setCurrencies={setCurrencies}
              />

            </div>

            <div className='pagination'>

              <div className='pagination__buttons'>

                <PaginationTotalResults 
                  foundResult={result?.result_counted !== undefined ? result.result_counted : false}
                  totalResult={result?.counters?.total !== undefined ? result?.counters?.total : false}
                  clearFilters={()=>clearFilters()}
                  userDetails={userDetails}
                  currentFolder={currentFolder}
                />

              </div>

              <PaginationPagination 
                perPage={perPage}
                setPerPage={setPerPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalResult={result?.result_counted !== undefined ? result.result_counted : false}
              />
              
            </div>

            <ContentMenu 
              saveSearch={saveSearch} 
              setSaveName={setSaveName}
              saved={saved}
              openedSave={openedSave}
              setOpenedSave={setOpenedSave}
              checkAll={checkAll}
              chosenItems={chosenItems}
              selectedAll={selectedAll}
              foundResult={result?.result_counted !== undefined ? result.result_counted : false}
              userCounter={userCounter}
              userPlan={userPlan}
              currentFolder={currentFolder}
              pageNumber={pageNumber}
              resultIds={resultIds}
              userDownloadIds={userDownloadIds}
              setUserDownloadIds={setUserDownloadIds}
              getCookie={getCookie}
              downloadExcel={downloadExcel}
              setUserCounter={setUserCounter}
              chosenOnThisPage={chosenOnThisPage}
              allChosen={allChosen}
              contentMenuHide={contentMenuHide}
              userFolders={userFolders}
              setUserFolders={setUserFolders}
              setFoldersOpened={setFoldersOpened}
              setChosenItems={setChosenItems}
              setSelectedAll={setSelectedAll}
              setAllChosen={setAllChosen}
              singleDownloadState={singleDownloadState}
              setSingleDownloadState={setSingleDownloadState}
              modalDownload={modalDownload}
              setModalDownload={setModalDownload}
            />

            <div className={'results'}>

                <Content 
                requestParams={requestParams}
                result={result}
                setResult={setResult}
                chosenItems={chosenItems}
                setChosenItems={setChosenItems}
                userDownloadIds={userDownloadIds}
                userViewsIds={userViewsIds}
                setUserDownloadIds={setUserDownloadIds}
                setUserViewsIds={setUserViewsIds}
                userCounter={userCounter}
                setUserCounter={setUserCounter}
                getCookie={getCookie}
                userFolders={userFolders}
                setUserFolders={setUserFolders}
                allChosen={allChosen}
                setAllChosen={setAllChosen}
                userPlan={userPlan}
                userExpired={userExpired}
                pageNumber={pageNumber}
                // setLimitViews={setLimitViews}
                clearFilters={()=>clearFilters()}
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                setResultIds={setResultIds}
                SingleDownload={SingleDownload}
                setContentMenuHide={setContentMenuHide}
                changeBookmarkCounter={changeBookmarkCounter}
                setBookmarksCounter={setBookmarksCounter}
                setModalDownload={setModalDownload}
                currencies={currencies}
                />

                {/* <SalesIQ /> */}

            </div>

            <div className='content-bottom'>

              {result?.result_counted > 0 ?
                <PaginationPagination 
                  perPage={perPage}
                  setPerPage={setPerPage}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalResult={result?.result_counted !== undefined ? result.result_counted : false}
                />
              : null}

            </div>
            
            {/* {userPlan ?

              <FeedbackForm 
              setFeedback={setFeedback}
              feedbackForm={feedbackForm}
              setFeedbackForm={setFeedbackForm}
              getCookie={getCookie}
              setUserCounter={setUserCounter}
              userCounter={userCounter}
              setFeedbackUserStatus={setFeedbackUserStatus}
              />

            :
            null
            } */}

          </div>

      </div>

      :
      <div className='preloader'>
          <div className='preloader__logo'>

          <img src={logoPng} alt="Vinaty Logo"/>

          </div>

        <Box className ='no-access__circle-loader' sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>


        {!wrongToken ? 

        <div className='no-access__text'>

          <span>Checking permissions. Please wait...</span>
          
        </div>

        : null}

      </div>}

    </ThemeProvider>

  );

}

export default App;
