import React, { useState } from 'react';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EqualizerIcon from '@mui/icons-material/Equalizer';
// import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import NotificationImportantSharpIcon from '@mui/icons-material/NotificationImportantSharp';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';

import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

export default function UpdatesList(props) {

    const {
        updateArrState
    } = props;

    const [openListNews,setOpenListNews] = useState(false);
    const [currentNews,setCurrentNews] = useState(0);


    function convertDate(dateIn) {
        // if (dateIn) {
            var dateOut = 'No date';
            if (dateIn !== '0') {
            var months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            var year = "'" + dateIn?.substring(2,4);
            var month = months[dateIn?.substring(5,7).replace(/^0+/, '') - 1];

            var day = dateIn?.substring(8,10);
            dateOut = month + ' ' + day  + ', ' + year;
            // }
            return dateOut;
        }
    }

    function funcShowNext(e) {
        e.stopPropagation();
        if (currentNews + 3 < updateArrState?.length) {
            var thisItem = document.getElementById('shown-news');
            thisItem.classList.remove('updates-list__center--active');
            
            // setTimeout(()=>{
                setCurrentNews(currentNews + 3);
                thisItem.classList.add('updates-list__center--active');
            // },250);
            
        }
    }
    function funcShowPrev(e) {
        e.stopPropagation();
        if ((currentNews/3 <= (updateArrState?.length - 1)) && currentNews != 0) {
            var thisItem = document.getElementById('shown-news');
            thisItem.classList.remove('updates-list__center--active');
            // setTimeout(()=>{
                setCurrentNews(currentNews - 3);
                thisItem.classList.add('updates-list__center--active');
            // },250);
        }
    }

    return (

        <div className='updates-list'>
            
            <div className='updates__heading'>

                <NotificationImportantSharpIcon 
                className='updates__h-icon'
                color="primary_icon_light"
                size="small"
                />

                <span className='updates__h-text'>
                    News and Recent Database Updates
                </span>

                <Button
                onClick={() => setOpenListNews(openListNews => !openListNews)}
                sx={{textTransform: 'none'}}
                variant="outlined"
                color={'primary'}
                size="small"
                className={'updates__button button--primary' + (openListNews ? ' updates__button--opened': ' updates__button--closed')}
                endIcon={<ChevronRightSharpIcon/>}
                >
                    <span className='updates__btn-text'>{openListNews ? 'Close': 'Open'}</span>
                </Button>
            </div>
            

            <Collapse className='updates__container' in={openListNews} timeout="auto" unmountOnExit>
                        
            <div className='updates-list__inner'>

                <div className='updates-list__left'>

                    <div onClick={(e)=>funcShowPrev(e)} className={'updates-list__actions' + (currentNews == 0 ? ' updates-list__actions--inactive' : '')}>
                        
                        <KeyboardArrowDownIcon />

                    </div>

                </div>

                <div className='updates-list__center'>

                    {updateArrState?.map((item,id)=>{
                        if (id === currentNews || id === currentNews + 1 || id === currentNews + 2) {

                        return(
                            
                        <div 
                        key={id} 
                        id='shown-news'
                        className='updates-list__body updates-list__center--active'
                        >

                            <div className='updates-list__h-container'>

                                <div className='updates-list__date'>

                                    {convertDate(item?.date)}

                                </div>

                                <div className={
                                'updates-list__icon' + 
                                (item?.icon === 'db' ? ' updates-list__icon--db' : item?.icon === 'blog' ? ' updates-list__icon--blog' : '')
                                }>

                                    {item?.icon === 'db' ? <SystemUpdateAltIcon /> :
                                    item?.icon === 'blog' ? <EqualizerIcon />
                                    : ''}
                                
                                </div>

                                <div className='updates-list__h-text'>

                                    {item?.heading !== '0' ? item?.heading : ''}
                                
                                </div>

                            </div>

                            <div className='updates-list__text'>

                                {item?.text}

                                <span className='updates-list__link'>
                                    {item?.link !== '0' ? 
                                    <a href={item?.link} target='_blank'>Read more</a>
                                    : null}
                                </span>

                            </div>

                        </div>
                        );
                    }
                    })}
                    
                </div>        

                <div className='updates-list__right'>

                    <div onClick={(e)=>funcShowNext(e)} className={'updates-list__actions' + ((currentNews + 3 >= updateArrState?.length) ? ' updates-list__actions--inactive' : '')}>
                        
                        <KeyboardArrowDownIcon />

                    </div>

                </div>


            </div>

            </Collapse>

        </div>
    );
}