import React, { useEffect, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import FolderIcon from '@mui/icons-material/Folder';
import EmailIcon from '@mui/icons-material/Email';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import TableRowsIcon from '@mui/icons-material/TableRows';
import StarIcon from '@mui/icons-material/Star';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import BackspaceIcon from '@mui/icons-material/Backspace';
import LockIcon from '@mui/icons-material/Lock';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export default function FoldersMultipleAdd(props) {

    const {
        userFolders,
        setUserFolders,
        getCookie,
        chosenItems,
        setFoldersOpened,
        checkAll,
        contentMenuHide,
        TextNoCompany
    } = props;
   
    // Open button top level
    const [openAdd, setOpenAdd] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);

    var urlApi;
   var TextNoFolders = "You don't have any folders. Please create one first.";
    const handleClickAdd = (event) => {
        // event.stopPropagation();
        if (openAdd === false) {
            setOpenAdd(true);
        } else {
            setOpenAdd(false);
        }
    };

    const handleClickRemove = (event) => {
        if (openRemove === false) {
            setOpenRemove(true);
        } else if (openRemove === true) {
            setOpenRemove(false);
        }
        // setOpenRemove(!openRemove);
    };

    const iconsArray = [
        {icon: FolderIcon,name:'FolderIcon'},
        {icon: EmailIcon,name:'EmailIcon'},
        {icon: DriveFolderUploadIcon,name:'DriveFolderUploadIcon'},
        {icon: FolderSpecialIcon,name:'FolderSpecialIcon'},
        {icon: FindInPageIcon,name:'FindInPageIcon'},
        {icon: FolderOffIcon,name:'FolderOffIcon'},
        {icon: TableRowsIcon,name:'TableRowsIcon'},
        {icon: StarIcon,name:'StarIcon'},
        {icon: HourglassEmptyIcon,name:'HourglassEmptyIcon'},
        {icon: DoneIcon,name:'DoneIcon'},
        {icon: CancelIcon,name:'CancelIcon'},
        {icon: MonetizationOnIcon,name:'MonetizationOnIcon'},
        {icon: NotificationImportantIcon,name:'NotificationImportantIcon'},
        {icon: FeedbackIcon,name:'FeedbackIcon'},
        {icon: LocalPhoneIcon,name:'LocalPhoneIcon'},
        {icon: BookmarkIcon,name:'BookmarkIcon'},
    ]

    // Render icon in the list of folders
    function findIcon(item) {
        for(let i = 0; i < iconsArray.length; i++) {
            if (iconsArray[i].name === item.icon) {
                var icon = iconsArray[i];
                return(
                    <icon.icon size={'small'} sx={{color: item.color, fontSize: '24px'}}/>
                );
            }
        }
    }

    // Action button add item to the folder
    function addItemToFolder(folder,action) {
        urlApi = process.env.REACT_APP_URL_API + 'folders';

        const queryAddToFolder = {
            "data": "item_to_folder",
            "action": action,
            "item_id": chosenItems,
            "folder_id": folder.folder_id,
            "token": getCookie()
        };

        fetch(urlApi, {
            method: 'POST',
            headers: {
                Authorization: process.env.REACT_APP_TOKEN_API,
                },
            cache: 'no-store',
            body: JSON.stringify(queryAddToFolder)
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            setUserFolders(data);

            // if (action === 'add') {
                // Open folders list
                setFoldersOpened(true);

                var targetFolder = document.querySelector('[data-folder="' + folder.folder_id + '"]');
                
                var buttonClick;

                if (action === 'add') {
                    buttonClick = document.getElementById('folder-add-container-' + folder.folder_id);
                } else if (action === 'remove') {
                    buttonClick = document.getElementById('folder-remove-container-' + folder.folder_id);
                }
                setTimeout(()=>{
                    buttonClick.querySelector('.folder-action-span').style.display = "flex";
                },200);

                setTimeout(()=>{
                    targetFolder.classList.add('folders__item--blink');
                    buttonClick.querySelector('.folder-action-span').classList.add('folder-action-span--blink');
                },300);

                setTimeout(()=>{
                    targetFolder.classList.remove('folders__item--blink');
                    buttonClick.querySelector('.folder-action-span').classList.remove('folder-action-span--blink');
                },2500);

                setTimeout(()=>{
                    buttonClick.querySelector('.folder-action-span').style.display = "none";
                },3000);
                
            // } 
            // else if (action === 'remove') {

            // }

        })
        .catch((error) => {
            // ("https://vinaty.com/login/");
            alert('Something went wrong during save of the content. Error code p004. Please report this code to our suport team.');
        });
    }

    //When Add to folder or remove from folder list is opened detect click outside
    function clickAwayMultibarAdd() {
        if (openAdd === true) {
            setOpenAdd(false);
        }
    }

    function clickAwayMultibarRemove() {
        if (openRemove === true) {
            setOpenRemove(false);
        }
    }

    return (
        <div className='folder-multi-actions'>

            <Tooltip title={userFolders.length < 1 ? TextNoFolders : chosenItems.length < 1 ? TextNoCompany : ""}>
            <div className='folder-multi-actions__add'>
                <Button
                disabled={contentMenuHide || chosenItems.length < 1 || userFolders.length < 1}
                className='content-menu__button' 
                onClick={(event) => handleClickAdd(event)}
                variant="contained"
                color={!openAdd ? 'primary_grey_light' : 'primary'}
                startIcon={<DriveFileMoveIcon />}
                endIcon={openAdd ? <ExpandLess /> : <ExpandMore />}
                >
                    Add

                </Button>
                
                <Collapse className="multibar-collapsed collapsed-inside folder-multi-actions__add-btn" in={openAdd} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={clickAwayMultibarAdd}>
                    <List component="div" disablePadding>

                        {userFolders?.map((folder,key)=>{

                            return(

                                <div className='folder-container' id={'folder-add-container-' + folder.folder_id}  key={key}>

                                    <ListItemButton 
                                    // key={key} 
                                    id={folder.folder_id} 
                                    sx={{ pl: 4 }}
                                    className='folder-action-btn'
                                    onClick={()=>addItemToFolder(folder,'add')}
                                    >
                                        <ListItemIcon >
                                            {findIcon(folder)}
                                        </ListItemIcon>

                                        <ListItemText primary={folder.name} />
                                        
                                            <ListItemIcon sx={{ justifyContent: 'flex-end'}}>
                                                <AddCircleIcon color={'green_dark'}/>
                                            </ListItemIcon>

                                    </ListItemButton>

                                    <span className='folder-action-span'>
                                        Added to folder
                                    </span>

                                </div>
                            );
                        })}

                    </List>
                    
                </ClickAwayListener>
                </Collapse>
            </div>
            </Tooltip>

            <Tooltip title={userFolders.length < 1 ? TextNoFolders : chosenItems.length < 1 ? TextNoCompany : ""}>
            <div className='folder-multi-actions__remove'>

                <Button
                disabled={contentMenuHide || chosenItems.length < 1 || userFolders.length < 1}
                onClick={() => handleClickRemove()}
                className='content-menu__button' 
                variant="contained"
                color={!openRemove ? 'primary_grey_light' : 'primary'}
                startIcon={<BackspaceIcon />}
                endIcon={openRemove ? <ExpandLess /> : <ExpandMore />}
                >
                    Remove
                </Button>
                
                <Collapse className="multibar-collapsed collapsed-inside folder-multi-actions__remove-btn" in={openRemove} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={()=>clickAwayMultibarRemove()}>  
                    <List component="div" disablePadding>
                        {userFolders?.map((folder,key)=>{
                            return(
                                <div className='folder-container' id={'folder-remove-container-' + folder.folder_id}  key={key}>
                                <ListItemButton
                                id={folder.folder_id} 
                                sx={{ pl: 4 }}
                                className='folder-action-btn'
                                onClick={()=>addItemToFolder(folder,'remove')}
                                >
                                    <ListItemIcon >
                                        {findIcon(folder)}
                                    </ListItemIcon>

                                    <ListItemText primary={folder.name} />
                                    
                                        <ListItemIcon sx={{ justifyContent: 'flex-end'}}>
                                            <RemoveCircleIcon color={'red_dark'}/>
                                        </ListItemIcon>

                                </ListItemButton>

                                <span className='folder-action-span'>
                                        Removed from folder
                                </span>

                                </div>
                            );
                        })}

                    </List>
                    
                </ClickAwayListener>
                </Collapse>
            </div>
            </Tooltip>

        </div>
    );
}